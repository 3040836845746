import React from 'react'
import { RouteComponentProps } from 'react-router'
import { FormHeaderText } from 'src/components/Common'
import { SearchFeedContent } from 'src/components/Feed/SearchFeedContent'
import { contentTypeOptions } from 'src/models/Config'
import { HubElement } from 'src/models/Hub'
import { LearningContentType } from 'src/utils/content'

export type AddToContentModalProps = RouteComponentProps & {
  containerTitle: string
  contentTypeOptions: LearningContentType[]
  searchedContentIds: Record<string, boolean>
  handleAddContent: (obj: HubElement) => void
  customAction?: React.ReactNode
}

export const AddToContentModal: React.FC<AddToContentModalProps> = ({
  containerTitle,
  searchedContentIds,
  handleAddContent,
  customAction,
  ...props
}: AddToContentModalProps) => {
  return (
    <>
      <FormHeaderText heading={`Add content to your ${containerTitle}`} />
      <SearchFeedContent
        searchedContentIds={searchedContentIds}
        handleAddContent={handleAddContent}
        contentTypeOptions={contentTypeOptions}
        scrollableTargetId="add-to-content-modal"
        height="calc(40vh)"
        showHeader={false}
        {...props}
      />
      {customAction}
    </>
  )
}
