import React from 'react'
import { ContainerDivProps } from 'src/components/Common'
import { LearningObjectPreviewCard } from 'src/components/Content/LearningObjectPreviewCard'
import { SubmoduleTag } from 'src/components/CourseAuthoring/SubmoduleTag'
import { Paths } from 'src/constants/navigation'
import { Course } from 'src/models/Course'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Submodule } from 'src/models/Submodule'

export const CourseSubmodulePreviewCard: React.FC<{
  course: Course
  submodule: Submodule
  checkboxDisabled?: boolean
  style?: ContainerDivProps
  updateBreadCrumb?: boolean
}> = ({ course, submodule, style, checkboxDisabled, updateBreadCrumb = true }) => {
  const submoduleIndex = course.getAllSubmodules().findIndex((s) => submodule.id === s.id)
  const lockedFromIndex = course.getLockedFromIndex()
  const inProgress = course.progress === 1 ? false : submoduleIndex === lockedFromIndex - 1
  const locked = submoduleIndex >= lockedFromIndex && course.requireSequentialCompletion

  const handleContentClick = () => {
    if (locked) return
    sharedAppStateStore.navigate(
      Paths.getSubmodulePath(course.id, submodule.id),
      false,
      updateBreadCrumb
        ? {
            breadcrumbName: course.name,
            contentType: 'course',
          }
        : { retain: true }
    )
  }

  return (
    <LearningObjectPreviewCard
      objectId={submodule.id}
      inProgress={inProgress}
      isCompleted={submodule.isCompleted}
      onClickHandler={handleContentClick}
      cursorStyle={locked ? 'normal' : 'pointer'}
      checkBoxClickhandler={handleContentClick}
      locked={locked}
      contentTypeTitle={submodule.getSubmoduleTypeDisplay().toUpperCase()}
      checkboxDisabled={checkboxDisabled}
      imgComponent={<SubmoduleTag submodule={submodule} />}
      title={submodule.title}
      style={style}
    />
  )
}
