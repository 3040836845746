import React, { Component, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AppNavigation } from 'src/hoc/AppNavigation'
import { ErrorBoundry } from 'src/hoc/ErrorBoundry'
import { Loading } from 'src/hoc/Loading'
import { MetaData } from 'src/hoc/MetaData'
import {
  authRoutePaths,
  baseRoutePaths,
  learningRoutesPaths,
  feedRoutesPaths,
  Paths,
  adminManagerRoutesPaths,
} from 'src/constants/navigation'
import { sharedDataStore } from 'src/store/DataStore'
import { Analytics } from 'src/utils/Analytics'
import { sharedCanvasManager } from 'src/utils/Canvas'
import { Auth } from 'src/utils/Auth'
import ScriptsLoadingWrapper from 'src/hoc/ScriptsLoadingWrapper'
import { NotFound } from 'src/pages/NotFound'
import { FjRoute } from 'src/hoc/FjRoute'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import './antd.less'
import moment from 'moment'
import Role from 'src/models/enums/Role'
import { CONFIG_STORE_KEY } from 'src/models/Config'
import { ViewState, ViewWrapper } from 'src/components/Common/ViewWrapper'

const AuthRoutes = lazy(() => import(/* webpackChunkName: "AuthRoutes" */ 'src/routes/authRoutes'))
const BaseRoutes = lazy(() => import(/* webpackChunkName: "BaseRoutes" */ 'src/routes/baseRoutes'))
const LearningRoutes = lazy(() => import(/* webpackChunkName: "LearnRoutes" */ 'src/routes/learningRoutes'))
const FeedRoutes = lazy(() => import(/* webpackChunkName: "FeedRoutes" */ 'src/routes/feedRoutes'))
const AdminManagerRoutes = lazy(
  () => import(/* webpackChunkName: "AdminManagerRoutes" */ 'src/routes/adminManagerRoutes')
)
const Embed = lazy(() => import(/* webpackChunkName: "Embed" */ 'src/pages/Embed'))

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

export default class App extends Component<{}, { viewState: ViewState }> {
  constructor(props) {
    super(props)
    Analytics.loadAnalytics()
    sharedDataStore.setReferralCode()
    this.state = { viewState: 'loading' }
  }

  onUnload = (e) => {
    /*
      The API calls from componentDidMount method will happen multiple times when Feed page have the Embed component.
      To prevent that behavior we are storing API result and don't call it again.
      It will be called again only when user refreshes the browser
    */
    localStorage.removeItem(CONFIG_STORE_KEY)
  }

  async componentDidMount() {
    try {
      sharedAppStateStore.setChromeExtensionContext()
      await sharedDataStore.hydrateStore()
      await sharedAppStateStore.getExternalAcademy()
      await sharedAppStateStore.wrapAppLoading(Auth.refreshUserSession())
      Analytics.saveLocalTrackingData()
      window.addEventListener('beforeunload', this.onUnload)
      sharedCanvasManager.setup()
      this.setState({ viewState: 'idle' })
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      this.setState({ viewState: 'idle' })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  render() {
    return (
      <ViewWrapper
        viewState={this.state.viewState}
        errorStateProps={{ imgMinWidth: '20vw', title: 'Whoops :(', subtitleLine1: 'Failed to load webapp' }}
      >
        <Loading>
          <ErrorBoundry>
            <MetaData>
              <Router>
                <ScriptsLoadingWrapper>
                  <AppNavigation>
                    <Suspense fallback={<Loading isSuspenseFallback />}>
                      <Switch>
                        <FjRoute exact allowedRoles={[Role.Faas]} path={Paths.embed} component={Embed} />
                        <Route path={authRoutePaths}>
                          <AuthRoutes />
                        </Route>
                        <Route path={learningRoutesPaths}>
                          <LearningRoutes />
                        </Route>
                        <Route path={adminManagerRoutesPaths}>
                          <AdminManagerRoutes />
                        </Route>
                        <Route path={feedRoutesPaths}>
                          <FeedRoutes />
                        </Route>
                        <Route exact path={baseRoutePaths}>
                          <BaseRoutes />
                        </Route>
                        <FjRoute path={Paths.notFound} component={NotFound} />
                      </Switch>
                    </Suspense>
                  </AppNavigation>
                </ScriptsLoadingWrapper>
              </Router>
            </MetaData>
          </ErrorBoundry>
        </Loading>
      </ViewWrapper>
    )
  }
}
