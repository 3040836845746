import React from 'react'
import { ContainerDivProps } from 'src/components/Common'
import { LearningObjectPreviewCard } from 'src/components/Content/LearningObjectPreviewCard'
import { ContentTag } from 'src/components/Feed/ContentTag'
import { Paths } from 'src/constants/navigation'
import { LearningPath, LearningPathContent } from 'src/models/LearningPath'
import { APIProvider } from 'src/network/APIProvider'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { getFeedContentData, getFeedContentTitle, getFeedContentTargetLink } from 'src/utils/content'

interface LearningPathViewContentCardProps {
  locked: boolean
  learningPath: LearningPath
  feedContent: LearningPathContent
  inProgress?: boolean
  checkboxDisabled?: boolean
  handleLearningPathUpdate: () => void
  insideLearningPathContentModal?: boolean
  selected?: boolean
  style?: ContainerDivProps
}

export const LearningPathViewContentCard: React.FC<LearningPathViewContentCardProps> = ({
  learningPath,
  feedContent,
  inProgress = false,
  locked,
  handleLearningPathUpdate,
  checkboxDisabled,
  insideLearningPathContentModal,
  selected,
  style,
}) => {
  const contentType = feedContent.contentType
  const contentData = getFeedContentData(feedContent.contentData, contentType)
  const title = getFeedContentTitle(contentData)
  const linkTarget = getFeedContentTargetLink(contentData)
  const isCompleted = contentData.progress && contentData.progress === 1.0

  const handleContentCheck = async () => {
    if (!contentData.progress && contentType === 'task') {
      await APIProvider.updateLearningPathContentProgress(learningPath.id, feedContent.id)
      sharedAppStateStore.modalProps = undefined
      handleLearningPathUpdate()
    }
  }

  const handleContentClick = () => {
    if (locked) return
    sharedAppStateStore.learningPathContentModalProps = undefined
    if (contentType !== 'task') {
      if (insideLearningPathContentModal) {
        sharedAppStateStore.navigate(linkTarget, false, {
          retain: true,
          selectedLearningPathContentId: contentData.id,
        })
      } else {
        sharedAppStateStore.navigate(linkTarget, false, {
          path: Paths.getLearningPathPath({ id: learningPath.id }),
          selectedLearningPathContentId: contentData.id,
          breadcrumbName: learningPath.title,
          contentType: 'learningpath',
        })
      }
    } else {
      sharedAppStateStore.resetRoutesToLastLearningPath(contentData.id)
    }
  }

  return (
    <LearningObjectPreviewCard
      selected={selected}
      objectId={feedContent.id}
      inProgress={inProgress}
      isCompleted={isCompleted}
      cursorStyle={contentType !== 'task' && linkTarget && !locked ? 'pointer' : 'normal'}
      onClickHandler={handleContentClick}
      checkBoxClickhandler={handleContentCheck}
      locked={locked}
      contentTypeTitle={contentType === 'feedpost' ? 'Post' : contentType === 'playlist' ? 'collections' : contentType}
      checkboxDisabled={checkboxDisabled !== undefined ? checkboxDisabled : false}
      imgComponent={<ContentTag obj={contentData} includeFileType locked={locked} />}
      title={title}
      style={style}
    />
  )
}
