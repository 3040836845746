import { Tooltip } from 'antd'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { ExternalLink } from 'react-feather'
import { StaticContext } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { ArchiveBanner } from 'src/components/Classroom/ArchiveBanner'
import { ContainerDiv, DefaultButton, FjCard, FjText, ThreeDotMenuButton } from 'src/components/Common'
import BreadcrumbBar from 'src/components/Common/BreadcrumbBar'
import { FeedHTMLBodyParser } from 'src/components/Common/HTMLExpandablePreview'
import { ViewWrapper } from 'src/components/Common/ViewWrapper'
import { ContentMenu } from 'src/components/Feed/ContentMenu'
import { BorderlessContentTag } from 'src/components/Feed/ContentTag'
import { ViewTracker, handleMediaPlaybackRateChange } from 'src/components/Feed/FeedCard'
import { SfMetadataDisplay } from 'src/components/Feed/SfMetadataFormFields'
import { FeedPage } from 'src/components/Page/Page'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { DealRoom } from 'src/models/DealRoom'
import { Template } from 'src/models/Template'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { MediaTracker } from 'src/utils/Analytics'
import { getQueryParam } from 'src/utils/urlParams'

const DealRoomHeader: React.FC<{ dealRoom: DealRoom; onCreateTemplateSuccess: (template: Template) => void }> = ({
  dealRoom,
  onCreateTemplateSuccess,
}) => {
  return (
    <FjCard width="100%" display="flex" justifyContent="space-between" alignItems="start">
      <ContainerDiv display="flex" flexDirection="column" gap="8px">
        <BorderlessContentTag obj={dealRoom} showTemplate />
        <Tooltip title={dealRoom.title}>
          <FjText textAlign="left" fontWeight="bold" fontSize="26px" color={Colors.midnightBlue} rows={2}>
            {dealRoom.title}
          </FjText>
        </Tooltip>
        <SfMetadataDisplay obj={dealRoom} />
      </ContainerDiv>
      <ContainerDiv display="flex" gap="8px" alignItems="center">
        <DefaultButton
          buttonType="primary"
          image={<ExternalLink />}
          title={sharedAppStateStore.isMobile ? '' : 'Share'}
          clicked={() => dealRoom.openEditSharedContentModal()}
        />
        <ContentMenu
          obj={dealRoom}
          hideShareExternally
          onDeleteSuccess={() => sharedAppStateStore.navigate(Paths.getLibraryPath('dealroom'))}
          onCreateTemplateSuccess={onCreateTemplateSuccess}
        >
          <ThreeDotMenuButton />
        </ContentMenu>
      </ContainerDiv>
    </FjCard>
  )
}

interface DealRoomRendererProps {
  dealRoom: DealRoom
  card?: boolean
  updateFjEmbedLinksForAnonymousViewing?: boolean
  password?: string
  trackingContext?: object
}

export class DealRoomRenderer extends React.Component<DealRoomRendererProps> {
  mediaTrackers: MediaTracker[] = []

  componentDidMount(): void {
    this.setupMediaTrackers()
  }

  componentDidUpdate(prevProps: Readonly<DealRoomRendererProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.dealRoom.id !== this.props.dealRoom.id) {
      this.pauseAllMedia()
      this.mediaTrackers = []
      this.setupMediaTrackers()
    }
  }

  setupMediaTrackers = () => {
    if (this.mediaTrackers.length) return
    const { dealRoom, updateFjEmbedLinksForAnonymousViewing } = this.props
    const media = this.findMedia()
    if (media.length) {
      for (let i = 0; i < media.length; i++) {
        const mediaElement = media[i]
        const context = { deal_room_id: dealRoom.id }
        if (updateFjEmbedLinksForAnonymousViewing) context['shared_content_id'] = dealRoom.sharedContentId
        if (mediaElement.getAttribute('data-document-id'))
          context['document_id'] = mediaElement.getAttribute('data-document-id')
        this.mediaTrackers.push(new MediaTracker(mediaElement, context))
        handleMediaPlaybackRateChange(mediaElement)
      }
    }
  }

  findMedia = () =>
    document.getElementById(this.props.dealRoom.getTrackingElementId()).querySelectorAll('video, audio') || []

  pauseAllMedia = () => {
    for (let media of this.findMedia()) {
      media.pause()
    }
  }
  render() {
    const { dealRoom, card = true, updateFjEmbedLinksForAnonymousViewing, password, trackingContext } = this.props
    const Wrapper = card ? FjCard : ContainerDiv
    return (
      <Wrapper id={dealRoom.getTrackingElementId()} textAlign="left" overflow="auto">
        {FeedHTMLBodyParser(dealRoom.content, {
          sharedContentId: updateFjEmbedLinksForAnonymousViewing ? dealRoom.sharedContentId : undefined,
          sharedContentPassword: password,
          sharedContentTrackingContext: trackingContext,
        })}
      </Wrapper>
    )
  }
}

@observer
export class DealRoomView extends React.Component<RouteComponentProps<{ id: string }>> {
  @observable viewState: 'initialLoad' | 'loading' | 'notFound' | 'idle' = 'initialLoad'
  @observable dealRoom: DealRoom

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    await this.fetchDealRoom()
    if (this.viewState === 'idle' && getQueryParam('viewAnalytics') === 'true') {
      sharedAppStateStore.dealRoomAnalyticsModalProps = { dealRoom: this.dealRoom }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<RouteComponentProps<{ id: string }, StaticContext, unknown>>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (this.props.match.params.id !== prevProps.match.params.id) this.fetchDealRoom()
    else if (this.props.location.search !== prevProps.location.search)
      sharedAppStateStore.dealRoomAnalyticsModalProps = { dealRoom: this.dealRoom }
  }

  fetchDealRoom = async () => {
    try {
      const { id } = this.props.match.params
      if (!id) {
        this.viewState = 'notFound'
        return
      }
      this.viewState = 'loading'
      this.dealRoom = await DealRoom.get(id)
      this.viewState = 'idle'
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      this.viewState = 'notFound'
    }
  }

  onDeleteConfirm = async (dealRoom: DealRoom) => {
    try {
      await dealRoom.delete()
      sharedAppStateStore.navigate(Paths.getLibraryPath('dealroom'))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onCreateTemplateSuccess = (template: Template) =>
    (this.dealRoom = DealRoom.fromData({ ...this.dealRoom, templateId: template.id }))

  render() {
    const { dealRoom } = this
    return (
      <FeedPage title={dealRoom?.title ? `${dealRoom.title} | Deal Room` : 'Deal Room'}>
        <ViewWrapper viewState={this.viewState}>
          {this.viewState === 'idle' ? (
            <>
              <BreadcrumbBar />
              <ContainerDiv display="flex" flexDirection="column" gap="18px">
                <ViewTracker obj={dealRoom} observerThreshold={0} source="deal_room" />
                <ArchiveBanner
                  obj={dealRoom}
                  onEditClick={() =>
                    sharedAppStateStore.navigate(Paths.getDealRoomPath({ id: dealRoom.id, isAuthoring: true }))
                  }
                  onArchiveSuccess={this.fetchDealRoom}
                  onDeleteConfirm={this.onDeleteConfirm}
                />
                <DealRoomHeader dealRoom={dealRoom} onCreateTemplateSuccess={this.onCreateTemplateSuccess} />
                <DealRoomRenderer dealRoom={dealRoom} />
              </ContainerDiv>
            </>
          ) : null}
        </ViewWrapper>
      </FeedPage>
    )
  }
}
