import styled from 'styled-components'
import { Colors } from 'src/constants/colors'
import React from 'react'
import { FjText } from 'src/components/Common'
import { camelToSentenceCase } from 'src/utils/format'

const CategoryContainer = styled.div<{ isSubTab: boolean }>`
  position: relative;
  border-radius: 10px;
  border: 1px solid ${Colors.sharkOpacity10};
  background: ${Colors.white};
  width: ${(props) => (props.isSubTab ? '95%' : '100%')};
  margin-bottom: 5px;
  padding: 10px 18px;
  cursor: pointer;
  transition: 0.2s;
  margin-left: ${(props) => (props.isSubTab ? '7%' : '0%')};

  :hover {
    background: ${Colors.hawkesBlue};
  }
`

const CategoryHighlight = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px 0px 0px 5px;
  width: 7px;
  height: 100%;
  background-color: ${Colors.texasRose};
`

export const CategoryItem: React.FC<{
  category: string
  active?: boolean
  onClick?: () => void
  isSubTab?: boolean
}> = ({ category, active, onClick, isSubTab }) => {
  return (
    <CategoryContainer onClick={onClick} isSubTab={isSubTab}>
      {active && <CategoryHighlight />}
      <FjText fontSize="16px" color={Colors.cornflowerBlue} fontWeight="semi-bold" textTransform="capitalize">
        {camelToSentenceCase(category)}
      </FjText>
    </CategoryContainer>
  )
}
