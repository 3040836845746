import React, { Component } from 'react'
import { BarChart2, MessageCircle, Play, Send } from 'react-feather'
import { Divider, Image, Tooltip, Space } from 'antd'
import { observable, computed, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import moment, { Moment } from 'moment'
import { FeedPost, ReviewStatus } from 'src/models/FeedPost'
import { FeedComment } from 'src/models/FeedComment'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import {
  DefaultButton,
  ContainerDiv,
  DefaultLink,
  FjText,
  ReactButton,
  FavouriteButton,
  TagsDisplayList,
  AddToQueueButton,
  FjProgress,
  IFrame,
  FjCard,
  ThreeDotMenuButton,
  FeedEmptyContent,
  Loader,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { PostCommentForm } from 'src/components/Feed/PostCommentForm'
import { LibraryCardRow } from 'src/components/Library/LibraryCardRow'
import { sharedDataStore } from 'src/store/DataStore'
import { CommentableItem, CommentList } from 'src/components/Feed/FeedComments'
import { Analytics, MediaTracker } from 'src/utils/Analytics'
import { Prompt } from 'src/models/Prompt'
import { FeedPlaylist, PlaylistElement } from 'src/models/FeedPlaylist'
import EmptyPrompt from 'src/assets/img/empty-prompt.svg'
import GetFeedbackWhite from 'src/assets/icons/GetFeedbackWhite.svg'
import { LearningPath } from 'src/models/LearningPath'
import HTMLExpandablePreview, { FeedHTMLBodyParser } from 'src/components/Common/HTMLExpandablePreview'
import { fetchContentById, getFeedContentBody, getFeedContentType, getFeedContentTypeName } from 'src/utils/content'
import { Course } from 'src/models/Course'
import { Call } from 'src/models/Call'
import { Asset } from 'src/models/Asset'
import { APIProvider } from 'src/network/APIProvider'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { URLPreview } from 'src/components/Feed/URLPreview'
import parse from 'html-react-parser'
import { encodeS3URL } from 'src/utils/format'
import PDFRenderer from 'src/components/Common/PDFRenderer'
import { CustomMetadataFieldDisplay } from 'src/components/Feed/CustomMetadataFields'
import { FjEvent } from 'src/models/FjEvent'
import { PageRenderer } from 'src/pages/PageView'
import { SfMetadataDisplay } from 'src/components/Feed/SfMetadataFormFields'
import RespondToPrompt from 'src/components/Feed/RespondToPrompt'
import { ContentTag } from 'src/components/Feed/ContentTag'
import { DateInfoPopover } from 'src/components/Feed/DateInfoPopover'
import { ContentMenu } from 'src/components/Feed/ContentMenu'
import TranscriptView from 'src/components/Feed/TranscriptView'
import { Document } from 'src/models/Document'
import { MSDocRenderer } from 'src/components/Common/MSDocRenderer'
import { ContentTitle } from 'src/components/Feed/ContentTitle'
import { LearningPathPreview } from 'src/components/LearningPath/LearningPathPreview'
import { CoursePreview } from 'src/components/Course/CoursePreview'
import { generateID } from 'src/utils/token'
import { Template } from 'src/models/Template'
import { AssetAISummaryView } from 'src/components/Search/AIGeneratedSummary'

const FEED_POST_SCROLLING_VIEW_THRESHOLD = 3000 // duration to wait before counting as a view (in milliseconds)

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '%ds',
    ss: '%ds',
    m: '%dmin',
    mm: '%dmin',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: '%dm',
    MM: '%dm',
    y: '%dy',
    yy: '%dy',
  },
})

export const handleMediaPlaybackRateChange = (media: HTMLVideoElement | HTMLAudioElement) => {
  media.addEventListener('ratechange', (e) => {
    const newPlaybackRate = (e.target as HTMLVideoElement | HTMLAudioElement).playbackRate
    if (newPlaybackRate !== sharedDataStore.localSettings.playbackRate) {
      sharedDataStore.localSettings = { ...sharedDataStore.localSettings, playbackRate: newPlaybackRate }
    }
  })

  media.addEventListener(
    'play',
    () =>
      (media.playbackRate = sharedDataStore.localSettings.playbackRate ? sharedDataStore.localSettings.playbackRate : 1)
  )
}

interface ITrackableObject {
  id: string
  getTrackingElementId: () => string
  getViewTrackingEventType: () => string
  getViewTrackingObjIdKey: () => string
  viewTrackingThresholdReached: (start: Moment) => boolean
}

interface ViewTrackerProps {
  obj: ITrackableObject
  source: string
  offsetDuration?: number
  observerThreshold?: number
  context?: object
}
export class ViewTracker extends Component<ViewTrackerProps> {
  observer: IntersectionObserver
  start?: moment.Moment
  delayTimerId?: number
  intervalId?: number
  event: FjEvent
  data: any = {}

  constructor(props: ViewTrackerProps) {
    super(props)
    /*
      Threshold is the percentage of the post has to be visible before it counts as visible.
      e.g. 0.5 means at least 50% of pixels of the target must be visible for callback to trigger
      0 means if anything is visible it will count, 1.0 means everything must be visible
    */
    const threshold = props.observerThreshold === undefined ? 0.25 : props.observerThreshold
    this.observer = new IntersectionObserver(this.observerCallback, { threshold })
  }

  componentDidMount() {
    this.observe()
  }

  componentDidUpdate(prevProps: ViewTrackerProps) {
    if (prevProps.obj.id !== this.props.obj.id) {
      this.endTracking()
      this.clearTimer()
      this.observer.disconnect()
    }
    if (this.props.obj.id) this.observe()
  }

  componentWillUnmount() {
    this.endTracking()
  }

  observe = () => {
    const { obj } = this.props
    this.observer.observe(document.querySelector(`#${obj.getTrackingElementId()}`))
  }

  observerCallback = ([entry]: IntersectionObserverEntry[]) => {
    const { offsetDuration } = this.props
    if (!this.delayTimerId && !this.start && entry.isIntersecting) {
      this.delayTimerId = window.setTimeout(
        () => {
          this.startTracking()
          this.clearTimer()
        },
        offsetDuration === undefined ? FEED_POST_SCROLLING_VIEW_THRESHOLD : offsetDuration
      )
    } else if (!entry.isIntersecting) {
      this.endTracking()
      this.clearTimer()
    }
  }

  saveViewEvent = async (data: any) => {
    const { obj } = this.props
    this.data = data
    if (obj.viewTrackingThresholdReached(this.start)) {
      this.event = this.event ? FjEvent.fromData({ ...this.event }) : new FjEvent()
      await this.event.save({
        eventType: obj.getViewTrackingEventType(),
        user: sharedDataStore.user.id,
        data: { ...this.data },
      })
      sharedDataStore.updateContextLearningPathProgess?.()
    }
  }

  startTracking = () => {
    if (this.start) return
    this.start = moment()
    const { obj, source, context } = this.props
    this.saveViewEvent({
      source,
      start: this.start.toISOString(),
      end: this.start.add(2, 'seconds').toISOString(),
      [obj.getViewTrackingObjIdKey()]: obj.id,
      ...(context || {}),
    })

    this.intervalId = window.setInterval(() => {
      this.saveViewEvent({ ...this.data, end: moment().toISOString() })
    }, 10000)
  }

  endTracking = () => {
    if (!this.start) return
    this.start = undefined
    this.clearInterval()
  }

  clearTimer = () => {
    if (this.delayTimerId) {
      window.clearTimeout(this.delayTimerId)
      this.delayTimerId = undefined
    }
  }

  clearInterval = () => {
    if (this.intervalId) {
      window.clearInterval(this.intervalId)
      this.intervalId = undefined
    }
  }

  render() {
    return null
  }
}

@observer
class FeedPostBodyRenderer extends Component<{ post: FeedPost; disablePreviews?: boolean }> {
  @observable show: boolean
  observer?: IntersectionObserver
  mediaTrackers: MediaTracker[] = []

  constructor(props: { post: FeedPost }) {
    super(props)
    makeObservable(this)
    this.show = !this.props.post.videoMetadata
    if (!this.show) this.observer = new IntersectionObserver(this.observerCallback, { rootMargin: '0px 0px 500px 0px' })
  }

  componentDidUpdate(prevProps: Readonly<{ post: FeedPost }>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.post.id !== this.props.post.id) {
      this.mediaTrackers = []
      this.setupMediaTrackers()
    }
  }

  componentDidMount() {
    if (this.observer) this.observer.observe(document.querySelector(`#${this.props.post.getTrackingElementId()}`))
    this.setupMediaTrackers()
  }

  componentWillUnmount() {
    if (this.observer) this.observer.unobserve(document.querySelector(`#${this.props.post.getTrackingElementId()}`))
  }

  observerCallback = ([entry]: IntersectionObserverEntry[]) => {
    if (!this.show && entry.isIntersecting) this.show = true
    else if (this.show && !entry.isIntersecting && this.mediaTrackers.length > 0) {
      for (const tracker of this.mediaTrackers) {
        tracker.mediaElement.pause()
      }
    }
  }

  setupMediaTrackers = () => {
    if (this.show && this.mediaTrackers.length === 0) {
      const container = document.querySelector(`#${this.props.post.getTrackingElementId()}`)
      const videos = container.getElementsByTagName('video')
      const audios = container.getElementsByTagName('audio')
      if (videos) {
        for (const video of videos) {
          this.mediaTrackers.push(new MediaTracker(video, { post_id: this.props.post.id, source: 'feed' }))
          handleMediaPlaybackRateChange(video)
        }
      }
      if (audios) {
        for (const audio of audios) {
          this.mediaTrackers.push(new MediaTracker(audio, { post_id: this.props.post.id, source: 'feed' }))
          handleMediaPlaybackRateChange(audio)
        }
      }
    }
  }

  render() {
    const { post, disablePreviews } = this.props

    return (
      <div style={{ textAlign: 'left' }}>
        {this.show && post.body ? (
          post.videoMetadata || disablePreviews ? (
            FeedHTMLBodyParser(post.body)
          ) : (
            <HTMLExpandablePreview
              rows={4}
              html={post.body}
              parserOptions={{ showEmbedAsLink: true, showMediaAsLink: true }}
            />
          )
        ) : null}
      </div>
    )
  }
}

interface LinkedObjectPreviewProps {
  feedPost: FeedPost
}

@observer
class LinkedObjectPreview extends Component<LinkedObjectPreviewProps> {
  contributorIds = new Set()

  render() {
    const { linkedObject, prompt } = this.props.feedPost
    if (!(linkedObject || prompt)) return null

    const isPlaylist = linkedObject instanceof FeedPlaylist
    const isPrompt = linkedObject instanceof Prompt
    const isLearningPath = linkedObject instanceof LearningPath
    const isCourse = linkedObject instanceof Course

    const contents = isPlaylist ? linkedObject.contents : isPrompt ? linkedObject.posts : []
    const contentBody = getFeedContentBody(linkedObject)

    return (
      <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start">
        {contentBody ? (
          <FjText textAlign="left" className="linked-content-description" width="100%" marginTop="4px">
            <HTMLExpandablePreview
              rows={2}
              html={contentBody}
              expandable
              parserOptions={{
                minimizeHeadings: true,
                showMediaAsLink: true,
                showEmbedAsLink: true,
              }}
            />
          </FjText>
        ) : null}
        {isLearningPath && <LearningPathPreview learningPath={linkedObject} />}
        {isCourse && <CoursePreview course={linkedObject} />}
        {(isPrompt || isPlaylist) &&
          (!contents.length ? (
            <ContainerDiv display="flex" alignItems="center" justifyContent="center" width="100%">
              <FeedEmptyContent
                imgSrc={EmptyPrompt}
                title="There are no responses to this prompt yet"
                subtitleLine1="Click the respond button below to be the first!"
              />
            </ContainerDiv>
          ) : (
            <ContainerDiv paddingTop="24px" borderRadius="4px" display="flex" width="100%" flexWrap="wrap">
              <FjCard width="100%" minHeight="150px" padding="4px" borderRadius={6}>
                <LibraryCardRow
                  objs={contents}
                  slidesToShow={4}
                  slidesToScroll={4}
                  playlistId={isPlaylist ? linkedObject.id : undefined}
                  promptId={isPrompt ? linkedObject.id : undefined}
                  hideAddToCollection
                  hideAddToQueue
                  responsive={[
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 998,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                />
              </FjCard>
            </ContainerDiv>
          ))}
      </ContainerDiv>
    )
  }
}

interface IAssetViewProps {
  asset: Asset
  isSharedView?: boolean
  hideDescription?: boolean
  videoTrackingSource?: string
  downloadTrackingContext?: object
  mediaWatchTrackingContext?: object
}

export class AssetView extends React.Component<IAssetViewProps> {
  mediaTrackers: MediaTracker[] = []
  iframeId = generateID()

  componentDidMount(): void {
    this.setupMediaTrackers()
  }

  componentDidUpdate(prevProps: Readonly<{ asset: Asset }>, prevState: Readonly<{}>, snapshot?: any): void {
    const { asset } = this.props
    if (prevProps.asset.id !== asset.id) {
      this.mediaTrackers = []
      this.setupMediaTrackers()
    } else if (
      prevProps.asset.hasGoogleDriveMetadata() &&
      asset.hasGoogleDriveMetadata() &&
      prevProps.asset.getLastEditedUtc() !== asset.getLastEditedUtc()
    ) {
      this.refreshIframe()
    }
  }

  fetchTranscript = async () => {
    const url = this.props.asset.fileUrl.replace(/\/$/, '') // remove trailing slash
    const pathComponents = url.split('/')
    const filename = pathComponents.pop().split('.')[0]
    const uniqueIdentifier = pathComponents.pop()
    return await Document.get(`${uniqueIdentifier}/${filename}`)
  }

  setCurrentVideoTime = (time: number) => {
    const { asset } = this.props
    const video = document.getElementById(`${asset.getTrackingElementId()}-video`)
    if (!video) return
    const videoElement = video as HTMLVideoElement
    videoElement.currentTime = time
  }

  downloadFile = async () => {
    try {
      const { asset, downloadTrackingContext } = this.props
      await APIProvider.downloadFile(asset.fileUrl, asset.title)
      Analytics.trackAssetDownload(sharedDataStore.user.id, asset.id, downloadTrackingContext)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  setupMediaTrackers = () => {
    if (this.mediaTrackers.length) return
    const { asset, videoTrackingSource, mediaWatchTrackingContext = {} } = this.props
    const media = document.getElementById(asset?.getTrackingElementId()).querySelectorAll('video, audio') || []
    if (media.length) {
      for (let i = 0; i < media.length; i++) {
        const mediaElement = media[i]
        this.mediaTrackers.push(
          new MediaTracker(mediaElement, {
            asset_id: asset.id,
            source: videoTrackingSource || 'classroom',
            ...mediaWatchTrackingContext,
          })
        )
        handleMediaPlaybackRateChange(mediaElement)
      }
    }
  }

  refreshIframe = () => {
    const { asset } = this.props
    const iframe = document.getElementById(this.iframeId) as HTMLIFrameElement
    /*
      Because iframe is not same origin we can't force a refresh using iframe.contentWindow.location.reload()
      Instead we have to update the url to trigger a reload but because the preview url is the same the browser doesn't refresh
      so had to resort to "updating" the url this way
     */
    if (iframe) {
      const newSrc = new URL(asset.getEmbedableUrl())
      newSrc.searchParams.set('update', generateID())
      iframe.src = newSrc.href
    }
  }

  render() {
    const { asset, hideDescription, isSharedView } = this.props
    const showVideoAsset = asset.fileType === 'video' && !!Asset.getFilename(asset.fileUrl)
    return (
      <ContainerDiv width="100%" marginVertical id={asset.getTrackingElementId()}>
        {asset.content ? (
          <FjCard textAlign="left">{FeedHTMLBodyParser(asset.content)}</FjCard>
        ) : asset.page ? (
          <PageRenderer style={{ border: `solid 1px ${Colors.hawkesBlue}` }} page={asset.page} trackViews={false} />
        ) : asset.linkedResourceUrl ? (
          <>
            {asset.embedHtml ? (
              <FjText display="block" textAlign="left">
                {parse(asset.embedHtml)}
              </FjText>
            ) : asset.urlPreview ? (
              <URLPreview {...asset.urlPreview} />
            ) : null}
          </>
        ) : (
          asset.fileUrl && (
            <>
              {asset.isImage() ? (
                <Image
                  onContextMenu={(e) => (asset.preventDownload ? e.preventDefault() : undefined)}
                  alt={asset.title}
                  src={encodeS3URL(asset.fileUrl)}
                  width="100%"
                />
              ) : asset.isVideo() ? (
                <>
                  <div className="rounded-video">
                    <video
                      id={`${asset.getTrackingElementId()}-video`}
                      src={encodeS3URL(asset.fileUrl)}
                      poster={asset.imgSrc()}
                      controls
                      controlsList="nodownload"
                      width="100%"
                      onContextMenu={(e) => (asset.preventDownload ? e.preventDefault() : undefined)}
                    />
                    {showVideoAsset ? (
                      <TranscriptView
                        setCurrentTime={this.setCurrentVideoTime}
                        fetchTranscript={this.fetchTranscript}
                      />
                    ) : null}
                  </div>
                </>
              ) : asset.isMSOffice() ? (
                <MSDocRenderer fileUrl={asset.fileUrl} />
              ) : !isSharedView && asset.shouldShowDrivePreview() && asset.getEmbedableUrl() ? (
                <IFrame
                  id={this.iframeId}
                  title={asset.title}
                  src={asset.getEmbedableUrl()}
                  width="100%"
                  height="100%"
                />
              ) : asset.isPDF() ? (
                <PDFRenderer title={asset.title} url={encodeS3URL(asset.fileUrl)} />
              ) : asset.isKeynote() ? (
                <PDFRenderer title={asset.title} url={encodeS3URL(asset.getEmbedableUrl())} />
              ) : (
                <DefaultButton
                  style={{ margin: '20px 0' }}
                  title={`Download: ${asset.title}`}
                  size="large"
                  buttonType="primary"
                  clicked={this.downloadFile}
                />
              )}
              {(asset.isPDF() || asset.getEmbedableUrl() || asset.isMSOffice() || asset.isImage() || asset.isVideo()) &&
              !asset.preventDownload ? (
                <ContainerDiv textAlign="right" marginTop="0.5rem">
                  <DefaultButton title="Download" buttonType="primary" clicked={this.downloadFile} />
                </ContainerDiv>
              ) : null}
            </>
          )
        )}
        {!hideDescription && asset.description && (
          <FjText display="block" textAlign="left" marginTop="30px" className="linked-content-description">
            {FeedHTMLBodyParser(asset.description)}
          </FjText>
        )}
      </ContainerDiv>
    )
  }
}

interface FeedCardProps {
  obj: PlaylistElement
  trackingSource?: string
  trackingOffsetDuration?: number
  trackingObserverThreshold?: number
  showComments?: boolean
  onArchiveSuccess?: () => void
  onEditSuccess?: (obj: FeedPost | Prompt) => void
  onToggleFavourite?: (obj: FeedPost) => void
  onDeleteSuccess?(): void
  onHidePostSuccess?: () => void
  respondToPrompt?: (prompt: Prompt, autoStartRecord?: boolean) => void
  disablePreviews?: boolean
  showReactionsModal?: (obj: CommentableItem, feedComment?: FeedComment) => void
  updateContentStatus?: (content: FeedPost | Asset, status: ReviewStatus) => void
  onEditDriveFileSuccess?: (asset: Asset) => void
  onCreateTemplateSuccess?: (template: Template) => void
  showAssetSummary?: boolean
}

@observer
export class FeedCard extends Component<FeedCardProps> {
  @observable collapsed: boolean = true
  @observable showCommentForm: boolean = false
  @observable commentsList: FeedComment[] = []
  @observable favoriteButtonDisabled = false
  @observable likeButtonDisabled = false
  @observable loadingLinkedObject = false
  actionComment?: FeedComment
  onDelete?: (comment: FeedComment) => void
  isMobile = sharedAppStateStore.isMobile

  static defaultProps = {
    trackingOffsetDuration: 0,
    trackingObserverThreshold: 0,
  }

  constructor(props: FeedCardProps) {
    super(props)
    makeObservable(this)
  }

  @computed get displayingComments() {
    const { obj } = this.props
    if (obj instanceof Call) return []
    if (this.collapsed) return obj.latestComments
    return this.commentsList.filter((c) => c.isParent())
  }

  componentDidMount() {
    this.getLinkedObject()
  }

  getLinkedObject = async () => {
    if (this.props.obj instanceof FeedPost && this.props.obj.linkedObjectId && this.props.obj.linkedContentType) {
      const { linkedObjectId, linkedContentType } = this.props.obj
      try {
        this.loadingLinkedObject = true
        const linkedObject: Prompt | FeedPlaylist | LearningPath | Course = await fetchContentById(
          linkedObjectId,
          linkedContentType
        )
        this.props.obj.linkedObject = linkedObject
      } catch (err) {
        sharedAppStateStore.handleError(err, undefined, false)
      } finally {
        this.loadingLinkedObject = false
      }
    }
  }

  showDeleteCommentConfirmation = (comment: FeedComment, onDelete?: (comment: FeedComment) => void) => {
    this.onDelete = onDelete
    this.actionComment = comment
    sharedAppStateStore.sharedConfirmDialogProps = {
      onConfirm: this.deleteComment,
      onCancel: this.closeDeleteCommentConfirmationDialog,
      content: 'Are you sure you want to delete this comment? You cannot undo this action.',
      confirmButtonTitle: 'Delete',
    }
  }

  deleteComment = async () => {
    const { obj } = this.props
    if (obj instanceof Call) return

    await this.actionComment.removeFrom(obj)
    if (this.onDelete) this.onDelete(this.actionComment)
    this.closeDeleteCommentConfirmationDialog()
  }

  onDeleteComment = (comment: FeedComment) => {
    const { obj } = this.props
    if (obj instanceof Call) return
    obj.latestComments = obj.latestComments.filter((c) => c.id !== comment.id)
    this.commentsList = this.commentsList.filter((c) => c.id !== comment.id)
    obj.commentCount -= 1
  }

  commentEdited = (comment: FeedComment) => {
    const { obj } = this.props
    if (obj instanceof Call) return
    obj.updateLatestCommentsIfExists(comment)
    const comments = [...this.commentsList]
    const index = comments.findIndex((c) => c.id === comment.id)
    if (index !== -1) {
      comments[index] = comment
      this.commentsList = comments
    }
  }

  closeDeleteCommentConfirmationDialog = () => {
    this.actionComment = undefined
    this.onDelete = undefined
  }

  commentDisplayText = () => {
    const { obj } = this.props
    if (obj instanceof Call) return
    return this.collapsed ? `View all ${obj.commentCount} comments` : 'Hide comments'
  }

  commentAdded = (comment: FeedComment) => {
    const { obj } = this.props
    if (obj instanceof Call) return
    let updatedComments = [...obj.latestComments]
    updatedComments.push(comment)
    obj.latestComments = updatedComments
    this.commentsList.push(comment)
    obj.commentCount++
    if (obj.latestComments.length === this.commentsList.length && this.commentsList.length > 2 && this.collapsed)
      this.collapsed = false
  }

  fetchComments = async () => {
    const { obj } = this.props
    if (obj instanceof Call) return

    try {
      this.commentsList = await FeedComment.getComments({}, obj)
    } catch (err) {
      sharedAppStateStore.handleError(err, 'Unable to retrieve comments')
    }
  }

  toggleCommentsDisplay = () => {
    const { obj } = this.props
    if (obj instanceof Call) return
    this.collapsed = !this.collapsed
    if (!this.collapsed) {
      if (this.commentsList.length === 0) this.commentsList = obj.latestComments
      this.fetchComments()
      this.showCommentForm = true
    }
  }

  showSummaryModal = () => {
    const { obj } = this.props
    if (obj instanceof Asset) {
      sharedAppStateStore.assetSummaryModalProps = {
        learningContent: { id: obj.id, title: obj.title },
      }
    } else if (obj instanceof FeedPost) {
      const targetObj = obj.linkedObject ? obj.linkedObject : obj
      sharedAppStateStore.postAnalyticsModalProps = {
        learningContent: { id: obj.id, title: targetObj.title },
      }
    }
  }

  handleToggleFavourite = async () => {
    try {
      const { obj, onToggleFavourite } = this.props
      this.favoriteButtonDisabled = true
      const targetObj = obj instanceof FeedPost && obj.linkedObject ? obj.linkedObject : obj
      await targetObj.toggleFavourited()
      if (targetObj instanceof FeedPost) onToggleFavourite?.(targetObj)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.favoriteButtonDisabled = false
    }
  }

  likeButtonClicked = async () => {
    const { obj } = this.props
    if (obj instanceof Call) return
    try {
      this.likeButtonDisabled = true
      await obj.likeButtonClicked()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.likeButtonDisabled = false
    }
  }

  render() {
    const {
      obj,
      trackingSource,
      trackingOffsetDuration,
      trackingObserverThreshold,
      respondToPrompt,
      showComments,
      disablePreviews,
      showAssetSummary,
    } = this.props
    const isFeedPost = obj instanceof FeedPost
    const isCall = obj instanceof Call
    const isAsset = obj instanceof Asset
    const isLinkedObjectPrompt = isFeedPost && obj.linkedObject instanceof Prompt

    const showLinkedContent = isFeedPost && obj.linkedObjectId && obj.linkedContentType
    // only exists when we want to render tags separately -- applicable to feedPost.linkedObject and asset
    const taggedObject = isFeedPost && obj.linkedObject ? obj.linkedObject : isAsset ? obj : undefined
    const targetObj = isFeedPost && obj.linkedObject ? obj.linkedObject : obj
    const isLearningPath = targetObj instanceof LearningPath
    const isCourse = targetObj instanceof Course

    const { updateContentStatus: updatePostStatus } = this.props
    const inFeedPage = window.location.href.includes(Paths.feed)

    return (
      <>
        <ViewTracker
          obj={obj}
          source={trackingSource || 'feed'}
          offsetDuration={trackingOffsetDuration}
          observerThreshold={trackingObserverThreshold}
        />
        <FjCard
          id={obj.getTrackingElementId()}
          marginBottom={inFeedPage ? '20px' : ''}
          position="relative"
          overflow="hidden"
        >
          <ContainerDiv
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={this.isMobile ? '12px' : '16px'}
          >
            {(isFeedPost || isAsset || (isCall && obj.author)) && (
              <ContainerDiv display="flex" gap={12} alignItems="center">
                <DefaultLink to={Paths.getProfilePath(obj.author.id)}>
                  <AuthorDisplay
                    showName={false}
                    author={obj.author}
                    fontWeight="semi-bold"
                    avatarSize={42}
                    onClick={() => {}}
                  />
                </DefaultLink>
                <ContainerDiv display="flex" flexDirection="column" alignItems="start">
                  <FjText textAlign="left">
                    {sharedAppStateStore.isMobile ? obj.author.getFirstName() : obj.author.fullName}
                  </FjText>
                  <DateInfoPopover obj={obj} />
                </ContainerDiv>
              </ContainerDiv>
            )}
            <ContainerDiv flexGrow={1} />
            <Space>
              <ContentTag obj={targetObj} upperCase showTemplate />
              {!updatePostStatus && (
                <ContentMenu
                  obj={obj}
                  placement="bottomRight"
                  onHidePostSuccess={this.props.onHidePostSuccess}
                  onArchiveSuccess={this.props.onArchiveSuccess}
                  onEditSuccess={this.props.onEditSuccess}
                  onDeleteSuccess={this.props.onDeleteSuccess}
                  onEditDriveFileSuccess={this.props.onEditDriveFileSuccess}
                  onCreateTemplateSuccess={this.props.onCreateTemplateSuccess}
                >
                  <ThreeDotMenuButton />
                </ContentMenu>
              )}
            </Space>
          </ContainerDiv>
          {isFeedPost && obj.prompt && respondToPrompt && inFeedPage && (
            <DefaultLink
              to={Paths.getClassroomPath({ postId: obj.id, promptId: obj.prompt.id })}
              style={{ textDecoration: 'none' }}
            >
              <RespondToPrompt
                expandable={false}
                question={obj.prompt.question}
                onRespondClicked={() => respondToPrompt(obj.prompt)}
              />
            </DefaultLink>
          )}
          {isFeedPost && obj.postType === 'sos' && (
            <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start">
              <ContainerDiv
                backgroundColor={Colors.texasRose}
                display="flex"
                alignItems="center"
                padding={this.isMobile ? '4px 7px' : '6px 10px'}
                position={this.isMobile ? 'relative' : 'absolute'}
                marginBottom={this.isMobile ? '9px' : '0px'}
                top="0px"
                right={this.isMobile ? '0px' : '100px'}
                borderBottomLeftRadius="4px"
                borderBottomRightRadius="4px"
                borderTopLeftRadius={this.isMobile ? '4px' : '0px'}
                borderTopRightRadius={this.isMobile ? '4px' : '0px'}
              >
                {!this.isMobile && <img height={20} src={GetFeedbackWhite} alt="feedback-icon" />}
                <FjText
                  fontSize="12px"
                  fontWeight="semi-bold"
                  color={Colors.white}
                  textTransform={this.isMobile ? 'uppercase' : 'initial'}
                >
                  Give Feedback
                </FjText>
              </ContainerDiv>
            </ContainerDiv>
          )}
          <ContainerDiv textAlign="left" display="flex" flexDirection="column" gap={4}>
            <ContentTitle obj={targetObj} />
            <SfMetadataDisplay obj={targetObj} />
            {isAsset ? (
              <>
                <CustomMetadataFieldDisplay obj={obj} />
                {showAssetSummary && <AssetAISummaryView asset={obj} />}
                <AssetView asset={obj} />
              </>
            ) : null}
            {isCall && (
              <>
                <FjText fontSize={this.isMobile ? '12px' : '14px'} textAlign="left">
                  Recorded: {obj.started.format(Call.startedDateFormat)}
                </FjText>
                <ContainerDiv padding="10px" margin="20px" borderRadius="10px">
                  <iframe title={obj.title} src={obj.embededCallUrl()} frameBorder="0" width="100%" height="550px" />
                  {obj.transcript?.length ? <TranscriptView initialSegments={obj.getSegments()} /> : null}
                </ContainerDiv>
              </>
            )}
            {isFeedPost && (
              <>
                {this.loadingLinkedObject ? (
                  <Loader />
                ) : showLinkedContent ? (
                  <>
                    {obj.linkedObject ? (
                      <LinkedObjectPreview {...{ feedPost: obj, respondToPrompt }} />
                    ) : (
                      <FjText fontSize="small" fontWeight="bold500">
                        Failed to fetch {getFeedContentTypeName({ contentType: obj.linkedContentType })}
                      </FjText>
                    )}
                  </>
                ) : (
                  <>
                    {obj.prompt ? <LinkedObjectPreview {...{ feedPost: obj, respondToPrompt }} /> : null}
                    <FeedPostBodyRenderer post={obj} disablePreviews={disablePreviews} />
                  </>
                )}
              </>
            )}
          </ContainerDiv>
          {isFeedPost && obj.urlPreview && (
            <ContainerDiv textAlign="left" marginBottom="15px">
              <Divider style={{ margin: '15px 0' }} />
            </ContainerDiv>
          )}
          <ContainerDiv display="flex" alignItems="center" justifyContent="space-between" margin="24px 0">
            {taggedObject && (
              <TagsDisplayList tags={taggedObject.tags} contentType={getFeedContentType(taggedObject)} />
            )}
            {isCourse && targetObj.progress !== 1 ? (
              <FjProgress height="12px" style={{ width: '180px' }} percent={Math.floor(targetObj.progress * 100)} />
            ) : isLearningPath && targetObj.nextStep() ? (
              <FjProgress
                height="12px"
                style={{ width: '180px' }}
                percent={targetObj.calculateCompletionPercentage()}
              />
            ) : null}
          </ContainerDiv>
          {!updatePostStatus && (
            <ContainerDiv flexDirection="column">
              <ContainerDiv textAlign="left" display="flex" justifyContent="space-between" alignItems="center">
                {(isFeedPost || isAsset) && (
                  <Space>
                    <ReactButton
                      reactionType="like"
                      reacted={obj.liked}
                      clicked={this.likeButtonClicked}
                      disabled={this.likeButtonDisabled}
                    />
                    {obj.likeCount !== 0 ? (
                      <DefaultButton
                        buttonType="text"
                        title={obj.likeCountDisplay()}
                        clicked={this.props.showReactionsModal ? () => this.props.showReactionsModal(obj) : () => null}
                      />
                    ) : null}
                    <DefaultButton
                      title={sharedAppStateStore.isMobile ? '' : 'Comment'}
                      image={<MessageCircle size={24} color={Colors.cornflowerBlue} />}
                      clicked={() => (this.showCommentForm = !this.showCommentForm)}
                    />
                  </Space>
                )}
                <ContainerDiv flexGrow={1} />
                <ContainerDiv display="flex" alignItems="center" gap="8px">
                  {(isFeedPost || isAsset) && !sharedDataStore.user.isFaasPartner() ? (
                    <Tooltip title="Analytics">
                      <DefaultButton
                        clicked={this.showSummaryModal}
                        image={<BarChart2 color={Colors.cornflowerBlue} size={24} />}
                      />
                    </Tooltip>
                  ) : null}
                  <FavouriteButton
                    favourited={isFeedPost && obj.linkedObject ? obj.linkedObject.isFavourited : obj.isFavourited}
                    clicked={this.handleToggleFavourite}
                    disabled={this.favoriteButtonDisabled}
                    accessRole={sharedDataStore.user.accessRole}
                  />
                  <AddToQueueButton
                    obj={isFeedPost && obj.linkedObject ? obj.linkedObject : obj}
                    color={Colors.cornflowerBlue}
                  />
                  {isLinkedObjectPrompt && !sharedAppStateStore.isMobile && (
                    <DefaultButton
                      buttonType="primary"
                      clicked={() => respondToPrompt(obj.linkedObject instanceof Prompt && obj.linkedObject, false)}
                      style={{ backgroundColor: Colors.cornflowerBlue }}
                      image={<Send size={16} />}
                      title="Respond"
                    />
                  )}
                  {isCourse && targetObj.progress !== 1 ? (
                    <DefaultLink to={Paths.getCoursePath({ id: targetObj.id })}>
                      <DefaultButton
                        image={<Play size={16} />}
                        buttonType="primary"
                        title={!targetObj.progress ? 'Begin' : 'Resume'}
                      />
                    </DefaultLink>
                  ) : isLearningPath && targetObj.nextStep() ? (
                    <DefaultLink to={Paths.getLearningPathPath({ id: targetObj.id })}>
                      <DefaultButton
                        image={<Play size={16} />}
                        buttonType="primary"
                        title={targetObj.lastCompletedStep() ? 'Resume' : 'Begin'}
                      />
                    </DefaultLink>
                  ) : null}
                </ContainerDiv>
              </ContainerDiv>
              {isLinkedObjectPrompt && sharedAppStateStore.isMobile && (
                <DefaultButton
                  buttonType="primary"
                  clicked={() => respondToPrompt(obj.linkedObject instanceof Prompt && obj.linkedObject, false)}
                  style={{ backgroundColor: Colors.cornflowerBlue }}
                  image={<Send size={16} />}
                  title="Respond"
                />
              )}
            </ContainerDiv>
          )}

          {(isFeedPost || isAsset) && updatePostStatus && (
            <>
              <Divider style={{ margin: '10px 0' }} />
              <ContainerDiv
                paddingTop
                paddingHorizontal
                display="flex"
                justifyContent="end"
                alignItems="center"
                gap="10px"
              >
                <DefaultButton
                  clicked={() => updatePostStatus(obj, 'rejected')}
                  title="Reject"
                  buttonType="destructive"
                />
                <DefaultButton clicked={() => updatePostStatus(obj, 'accepted')} title="Accept" buttonType="primary" />
              </ContainerDiv>
            </>
          )}
          {isFeedPost && obj.latestComments && obj.commentCount > obj.latestComments.length && (
            <ContainerDiv textAlign="left" marginTop="16px">
              <DefaultButton title={this.commentDisplayText()} clicked={this.toggleCommentsDisplay} />
            </ContainerDiv>
          )}
          {(isFeedPost || isAsset) && showComments !== false && (
            <>
              <ContainerDiv>
                {this.showCommentForm && <PostCommentForm obj={obj} commentAdded={this.commentAdded} />}
                <CommentList
                  obj={obj}
                  commentsList={this.displayingComments}
                  deleteComment={this.showDeleteCommentConfirmation}
                  onDeleteComment={this.onDeleteComment}
                  commentEdited={this.commentEdited}
                  showReactionsModal={this.props.showReactionsModal}
                />
              </ContainerDiv>
            </>
          )}
        </FjCard>
      </>
    )
  }
}
