import React from 'react'
import { Layout, Badge } from 'antd'
import { BadgeProps } from 'antd/lib/badge'
import { Home, File, Layout as LayoutIcon, BarChart2, X } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { FjText, ContainerDiv } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { mobileQuery } from 'src/pages/public/common'
import FJLogo from 'src/assets/icons/Sidebar/FJLogo.svg'
import FJLogoCompanyName from 'src/assets/icons/Sidebar/FJLogo-CompanyName.svg'
import { sharedDataStore } from 'src/store/DataStore'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { observer } from 'mobx-react'
import { sharedQueryClient } from 'src/store/QueryClient'

const { Sider } = Layout
const SIDE_NAV_BACKGROUND = `linear-gradient(180deg, #E9F4FF 8.85%, #D8E6FF 44.27%, #EADEFF 82.29%)`

interface IFeedNavMenuItemProps {
  icon: React.ReactElement
  to?: string
  clicked?: () => void
  text?: string
  style?: React.CSSProperties
  badgeProps?: BadgeProps
}

const menuItemStyle: React.CSSProperties = {
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '8px',
  cursor: 'pointer',
}

export const FeedNavMenuItem: React.FC<IFeedNavMenuItemProps> = ({
  icon,
  to = '',
  text = '',
  style = {},
  clicked = undefined,
  badgeProps,
}) => {
  const isMobile = useMediaQuery(mobileQuery)

  const iconComponent = badgeProps ? <Badge {...badgeProps}>{icon}</Badge> : icon

  const ItemContent = isMobile ? (
    <>
      {iconComponent}
      <FjText paddingLeft="14px" fontWeight="semi-bold" fontSize="11px" color={Colors.black}>
        {text}
      </FjText>
    </>
  ) : (
    <ContainerDiv display="flex" flexDirection="column" alignItems="center">
      {iconComponent}
      <FjText fontWeight="semi-bold" marginTop="6px" fontSize="11px" color={Colors.black}>
        {text}
      </FjText>
    </ContainerDiv>
  )

  const handleClickNavMenuItem = () => {
    sharedAppStateStore.resetBreadcrumbs()
    clicked?.()
  }

  return (
    <>
      {to ? (
        <NavLink
          className="sidebar-link"
          style={{
            ...menuItemStyle,
            ...style,
            justifyContent: isMobile ? 'flex-start' : 'center',
            borderRadius: isMobile ? '8px' : '',
          }}
          to={to}
          activeStyle={{ backgroundColor: Colors.hawkesBlue }}
          onClick={handleClickNavMenuItem}
        >
          {ItemContent}
        </NavLink>
      ) : (
        <div
          style={{
            ...menuItemStyle,
            ...style,
            justifyContent: isMobile ? 'flex-start' : 'center',
            borderRadius: isMobile ? '8px' : '',
          }}
          onClick={handleClickNavMenuItem}
        >
          {ItemContent}
        </div>
      )}
    </>
  )
}

const SideNavContainer: React.FC<{ children?: React.ReactNode; handleCloseNavbar?: (e: React.MouseEvent) => void }> = ({
  children,
  handleCloseNavbar,
}) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <ContainerDiv
      position={isMobile ? 'relative' : 'fixed'}
      display="flex"
      flexDirection="column"
      textAlign="left"
      height={'100vh'}
      width={isMobile ? '100%' : '80px'}
      paddingBottom="20px"
      background={SIDE_NAV_BACKGROUND}
    >
      <div
        style={{
          ...logoStyle,
          justifyContent: isMobile ? 'flex-start' : 'center',
        }}
      >
        {!isMobile ? <img width="28px" alt="logo" src={FJLogo} /> : <img alt="logo" src={FJLogoCompanyName} />}
        {isMobile && handleCloseNavbar && (
          <X
            size={20}
            style={{
              position: 'absolute',
              right: '18px',
              cursor: 'pointer',
            }}
            onClick={handleCloseNavbar}
          />
        )}
      </div>
      {children}
    </ContainerDiv>
  )
}

const logoStyle: React.CSSProperties = {
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '0 30px',
  height: '68px',
}

interface IFeedSideNavbarContentProps {
  contentBackgroundColor?: string
  handleNavbarOpenStatus?: (isOpen: boolean) => void
}

@observer
export class FeedSideNavbarContent extends React.Component<IFeedSideNavbarContentProps> {
  handleCloseNavbar = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.props.handleNavbarOpenStatus(false)
  }

  render() {
    const navIconProps = { color: Colors.black, size: 24 }
    let links = [
      <FeedNavMenuItem
        icon={<Home {...navIconProps} />}
        to={sharedDataStore.getLoginHandlerPath()}
        text="Home"
        style={{ margin: sharedAppStateStore.isMobile ? '20px 20px 10px 20px' : '' }}
        key="home"
        clicked={() => sharedQueryClient.invalidateQueries({ queryKey: ['badge_counts'], refetchType: 'none' })}
      />,
    ]
    links.push(
      <FeedNavMenuItem
        icon={<LayoutIcon {...navIconProps} />}
        to={Paths.library}
        text="Library"
        style={{ margin: sharedAppStateStore.isMobile ? '10px 20px' : '' }}
        key="library"
      />
    )
    if (sharedDataStore.user.isFaasAdmin() && process.env.REACT_APP_PAGES_ENABLED === 'true') {
      links.push(
        <FeedNavMenuItem
          icon={<File {...navIconProps} />}
          to={Paths.getPagePath()}
          text="Pages"
          style={{ margin: sharedAppStateStore.isMobile ? '10px 20px' : '' }}
          key="pages"
        />
      )
    }
    if (!(sharedDataStore.user.isFaasPublic() || sharedDataStore.user.isFaasPartner())) {
      links.push(
        <FeedNavMenuItem
          icon={<BarChart2 {...navIconProps} />}
          to={Paths.activityDashboard}
          text="Analytics"
          style={{ margin: sharedAppStateStore.isMobile ? '10px 20px' : '' }}
          key="analytics"
        />
      )
    }
    return <SideNavContainer handleCloseNavbar={this.handleCloseNavbar}>{links}</SideNavContainer>
  }
}

interface IFeedSideNavbarProps {
  contentBackgroundColor?: string
}

export const FeedSideNavbar: React.FC<IFeedSideNavbarProps> = ({ contentBackgroundColor }) => {
  return (
    <Sider width="80px" style={{ backgroundColor: Colors.fuscousGrey }} trigger={null}>
      <FeedSideNavbarContent contentBackgroundColor={contentBackgroundColor} />
    </Sider>
  )
}

interface IMobileFeedSidebarProps {
  setNavbarDrawerOpen: (isOpen: boolean) => void
}

export const MobileFeedSideNavbar: React.FC<IMobileFeedSidebarProps> = ({ setNavbarDrawerOpen }) => {
  return (
    <div style={{ margin: '-24px' }}>
      <FeedSideNavbarContent handleNavbarOpenStatus={setNavbarDrawerOpen} />
    </div>
  )
}

export const PublicFeedSidebar: React.FC = () => {
  return (
    <Sider width="80px" style={{ backgroundColor: Colors.fuscousGrey }} trigger={null}>
      <SideNavContainer />
    </Sider>
  )
}
