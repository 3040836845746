import { Row, Col, Space } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { Component } from 'react'
import { sharedDataStore } from 'src/store/DataStore'
import { titleCase } from 'src/utils/format'
import { ContainerDiv, FjFormItem, FjSelect, FjText } from 'src/components/Common'
import { Asset } from 'src/models/Asset'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { LearningPath } from 'src/models/LearningPath'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Colors } from 'src/constants/colors'
import sparkles from 'src/assets/icons/sparkles.png'

export const CustomMetadataFieldDisplay: React.FC<{
  obj: Asset | LearningPath | FeedPlaylist | Course
  color?: string
}> = ({ obj, color = Colors.tapa }) => {
  const { customFields } = obj

  if (!obj.hasCustomFields()) return null

  return (
    <Row>
      {Object.entries(customFields).map(([fieldName, fieldValue]) => (
        <Col key={`${fieldName}${fieldValue}`}>
          <ContainerDiv textAlign="left" marginRight="1rem">
            <Space align="center" size={7}>
              <FjText color={color} fontSize={sharedAppStateStore.isMobile ? '12px' : '14px'} textAlign="left">
                {titleCase(fieldName, '_')}:{` `}
                <FjText color={color} fontSize={sharedAppStateStore.isMobile ? '12px' : '14px'}>
                  {fieldValue}
                </FjText>
              </FjText>
            </Space>
          </ContainerDiv>
        </Col>
      ))}
    </Row>
  )
}

type CustomMetadataFieldsProps = {
  showAITooltip?: boolean
}

@observer
export class CustomMetadataFields extends Component<CustomMetadataFieldsProps> {
  render() {
    return (
      <Row gutter={16}>
        {sharedDataStore.customMetadataFieldNames.map((customFieldName) => (
          <Col sm={12} xs={24} key={customFieldName}>
            <FjFormItem
              name={`customFields[${customFieldName}]`}
              fieldtitle={titleCase(customFieldName, '_')}
              tiptitle={
                sharedDataStore.user.company.enableCustomFieldsSuggestion && this.props.showAITooltip
                  ? 'If left blank, Flockjay AI will automatically attempt to fill in these values. You will receive a notification when they are ready.'
                  : undefined
              }
              tipIcon={<img src={sparkles} alt="sparkles" width={20} />}
            >
              <FjSelect
                allowClear
                placeholder={titleCase(customFieldName, '_')}
                name={`customFields[${customFieldName}]`}
                options={sharedDataStore.config?.getCustomMetadataFieldOptions(customFieldName)}
              />
            </FjFormItem>
          </Col>
        ))}
      </Row>
    )
  }
}
