// @ts-nocheck
import '@salesforce/canvas-js-sdk'
import { Paths } from 'src/constants/navigation'
import { sharedAppStateStore } from 'src/store/AppStateStore'

class CanvasManager {
  sr: any
  oppLoaded = false

  setup = async () => {
    const params = new URLSearchParams(window.location.search)
    const b64SignedRequest = params.get('signed_request') || ''

    if (!b64SignedRequest) return

    const signedRequestString = atob(b64SignedRequest)
    this.sr = JSON.parse(signedRequestString)
  }

  navigateToOppIfNecessary = () => {
    if (!this.sr || !this.getOpportunityId() || this.oppLoaded) return
    // we have to only do this on first load to allow the user to navigate back to /feed if they want to from the opp context
    // otherwise they are stuck in a loop of trying to get to /feed but being navigated back to opp page
    this.oppLoaded = true
    sharedAppStateStore.navigate(Paths.getOpportunityPath({ id: this.getOpportunityId() }))
  }

  getOpportunityId = () => {
    try {
      return this.sr['context']['environment']['record']['Id']
    } catch (err) {
      // do nothing
    }
  }
}

const sharedCanvasManager = new CanvasManager()

export { sharedCanvasManager }
