import React, { useEffect, useMemo } from 'react'
import { Select } from 'antd'
import { observer } from 'mobx-react'
import { sharedDataStore, ViewMode, ViewModes } from 'src/store/DataStore'
import { camelToSentenceCase } from 'src/utils/format'
import { FilterSelect } from 'src/components/Feed//FeedPostList'

export const ViewSwitcher = observer(({ excludesViewModes = [] }: { excludesViewModes?: ViewMode[] }) => {
  const { viewMode } = sharedDataStore.localSettings
  const viewModeOptions = useMemo(
    () => ViewModes.filter((mode) => !excludesViewModes.includes(mode)),
    [excludesViewModes]
  )

  useEffect(() => {
    if (excludesViewModes.includes(viewMode)) {
      sharedDataStore.localSettings = { ...sharedDataStore.localSettings, viewMode: ViewModes[0] }
    }
  }, [excludesViewModes, viewMode])

  return (
    <FilterSelect
      value={viewMode}
      onChange={(mode: ViewMode) =>
        (sharedDataStore.localSettings = { ...sharedDataStore.localSettings, viewMode: mode })
      }
      style={{ width: 'max-content' }}
    >
      {viewModeOptions.map((mode) => (
        <Select.Option key={mode} value={mode}>
          {camelToSentenceCase(mode)}
        </Select.Option>
      ))}
    </FilterSelect>
  )
})
