import React, { useCallback, useMemo } from 'react'
import { Asset } from 'src/models/Asset'
import { DealRoom } from 'src/models/DealRoom'
import { Template } from 'src/models/Template'
import {
  ContainerDiv,
  DefaultLink,
  FjFormItem,
  FjInput,
  FjText,
  FormActionButtons,
  FormHeaderText,
} from 'src/components/Common'
import { Form, Formik } from 'formik'
import { isRequired } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Alert } from 'antd'
import { AlertCircle, ExternalLink } from 'react-feather'
import { getFeedContentTargetLink } from 'src/utils/content'

export type TemplateableLearningContentType = 'asset' | 'dealroom'

export type TemplatableItem = Asset | DealRoom

export interface ITemplateAuthoringModalProps {
  item: TemplatableItem | Template
  onCancel: () => void
  onSuccess: (template: Template) => void
}

export const TemplateAuthoringModal: React.FC<ITemplateAuthoringModalProps> = ({ item, onSuccess, onCancel }) => {
  const initialValues = useMemo(() => {
    if (item instanceof Template) return { name: item.name, learningContentId: item.learningContent.learningContentId }
    return { name: `[Template] ${item.title}`, learningContentId: item.learningContentId }
  }, [item])

  const alertText = useMemo(() => {
    if (item instanceof Template) return `This template was created using the ${item.learningContent.getTagTitle()}: `
    return `This template will be created using the ${item.getTagTitle()}: `
  }, [item])

  const learningContent = useMemo(() => (item instanceof Template ? item.learningContent : item), [item])

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const template = item instanceof Template ? item : new Template()
        const { data: newData } = await sharedAppStateStore.wrapAppLoading(template.save(data))
        onSuccess(Template.fromData(newData))
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    },
    [item, onSuccess]
  )

  return (
    <ContainerDiv textAlign="left">
      <FormHeaderText heading={item instanceof Template ? 'Edit Template' : 'Create a Template'} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <ContainerDiv textAlign="left" marginBottom>
            <Alert
              type="info"
              message={
                <ContainerDiv display="flex" gap="10px" alignItems="center">
                  <AlertCircle size={18} />
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <FjText>{alertText}</FjText>
                    <DefaultLink
                      style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                      color="linkBlue"
                      to={getFeedContentTargetLink(learningContent)}
                      onClick={() => (sharedAppStateStore.templateAuthoringModalProps = undefined)}
                    >
                      {learningContent.title}
                      <ExternalLink size={16} />
                    </DefaultLink>
                  </div>
                </ContainerDiv>
              }
            />
          </ContainerDiv>
          <FjFormItem fieldtitle="Template Name*" name="name" validate={isRequired}>
            <FjInput name="name" placeholder="Enter a name" />
          </FjFormItem>
          <FjFormItem name="learningContentId" style={{ display: 'none' }}>
            <FjInput name="learningContentId" />
          </FjFormItem>
          <FormActionButtons submitButtonLabel={item instanceof Template ? 'Update Template' : 'Create Template'} />
        </Form>
      </Formik>
    </ContainerDiv>
  )
}
