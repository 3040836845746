import { Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import { FjFormItem, FjDatePicker, Icon } from 'src/components/Common'
import Info from 'src/assets/icons/Info.svg'

export const ExpirationDateField = () => {
  return (
    <FjFormItem name="expiryDate" fieldtitle="Expiration Date">
      <FjDatePicker
        placeholder="Expiration Date"
        name="expiryDate"
        format={'MMM Do, YYYY'}
        disabledDate={(date) => date < moment().startOf('day')}
        style={{ width: 280 }}
      />
      <Tooltip placement="right" title="Content will expire on the selected day at midnight" arrowPointAtCenter>
        <Icon src={Info} marginLeft="5px" />
      </Tooltip>
    </FjFormItem>
  )
}
