import React, { Component, lazy, Suspense } from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { observable, makeObservable, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Asset } from 'src/models/Asset'
import {
  ContainerDiv,
  DefaultButton,
  FjCheckBox,
  FjCheckboxProps,
  FjDragUploader,
  FjFormItem,
  FjInput,
  FjMemoFormItem,
  FJStyledAutoComplete,
  FJStyledInput,
  FjText,
  FormActionButtons,
  FormHeaderText,
  GoogleDriveButton,
  GroupSelector,
  HubSelector,
  Icon,
  Loader,
  TagSelector,
  UploadThumbnailButton,
} from 'src/components/Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { uploadToS3 } from 'src/utils/S3Upload'
import { Col, Divider, Row, Tabs, Tooltip, Typography, UploadFile } from 'antd'
import { CheckCircle } from 'react-feather'
import { Colors } from 'src/constants/colors'
import { showNotification } from 'src/hoc/Notification'
import { sharedDataStore } from 'src/store/DataStore'
import { CallbackDoc } from 'react-google-drive-picker/dist/typeDefs'
import { combineValidations, isFile, isRequired, isURI } from 'src/utils/validation'
import { clearFalseyValues, formatDateToPST, isValidURL, truncate } from 'src/utils/format'
import { getFeedContentTargetLink } from 'src/utils/content'
import { CustomMetadataFields } from 'src/components/Feed/CustomMetadataFields'
import { Page } from 'src/models/Page'
import { SfMetadataFormFields } from 'src/components/Feed/SfMetadataFormFields'
import Info from 'src/assets/icons/Info.svg'
import { ExpirationDateField } from 'src/components/Feed/ExpirationDateField'
import HTMLExpandablePreview from 'src/components/Common/HTMLExpandablePreview'
import { Template } from 'src/models/Template'
import { UseTemplateInfoBox } from 'src/components/Feed/TemplateModal'

const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

const DIVIDER_STYLES: React.CSSProperties = { margin: '10px 0px' }

type AssetFormTab = 'upload' | 'linkedResourceUrl' | 'content'

interface IAssetBooleanFieldProps {
  tooltip?: string
  checkboxProps?: Omit<FjCheckboxProps, 'name'>
}

export const InternalUseField: React.FC<IAssetBooleanFieldProps> = ({
  tooltip = 'By checking this box users will be unable to create share links for this asset.',
  checkboxProps = {},
}) => {
  return (
    <FjFormItem name="isInternal">
      <FjCheckBox name="isInternal" {...checkboxProps}>
        Internal Use Only{' '}
        <Tooltip placement="bottomLeft" title={tooltip} arrowPointAtCenter>
          <Icon src={Info} marginLeft="5px" />
        </Tooltip>
      </FjCheckBox>
    </FjFormItem>
  )
}

export const PreventDownloadField: React.FC<IAssetBooleanFieldProps> = ({
  tooltip = 'By checking this box users will be unable to download this asset.',
  checkboxProps = {},
}) => {
  return (
    <FjFormItem name="preventDownload">
      <FjCheckBox name="preventDownload" {...checkboxProps}>
        Prevent Downloads{' '}
        <Tooltip placement="bottomLeft" title={tooltip} arrowPointAtCenter>
          <Icon src={Info} marginLeft="5px" />
        </Tooltip>
      </FjCheckBox>
    </FjFormItem>
  )
}

export const PreventDuplicateField: React.FC<IAssetBooleanFieldProps> = ({
  tooltip = 'By checking this box users will be unable to duplicate this asset.',
  checkboxProps = {},
}) => {
  return (
    <FjFormItem name="preventDuplicate">
      <FjCheckBox name="preventDuplicate" {...checkboxProps}>
        Prevent Duplicates{' '}
        <Tooltip placement="bottomLeft" title={tooltip} arrowPointAtCenter>
          <Icon src={Info} marginLeft="5px" />
        </Tooltip>
      </FjCheckBox>
    </FjFormItem>
  )
}

export interface IAssetFormProps {
  onSuccess: (asset: Asset, close: boolean) => void
  onCancel: () => void
  asset?: Asset
  isFileUploaded?: boolean
  isAssetUpdated?: boolean
}

@observer
export class AssetForm extends Component<IAssetFormProps> {
  @observable pageOptions = []
  @observable fileUrl: string = undefined
  @observable linkedResourceUrl: string = undefined
  @observable content: string = undefined
  @observable showAssetMetadataForm: boolean = false
  @observable activeTab: AssetFormTab = 'upload'
  @observable selectedPageInfo: { pageName?: string; id: string } = {
    pageName: '',
    id: undefined,
  }
  contentType: string = undefined
  cleanFilename: string = undefined
  googleDriveMetadata?: object
  formikRef

  constructor(props: IAssetFormProps) {
    super(props)
    makeObservable(this)
    this.formikRef = React.createRef()
  }

  componentDidMount(): void {
    sharedAppStateStore.assetModalProps.isFileUploaded = false
    const { fileUrl, linkedResourceUrl, page, googleDriveMetadata, contentType, content } =
      this.props.asset || new Asset()
    this.showAssetMetadataForm = !!fileUrl || !!linkedResourceUrl || !!page?.id || !!content

    this.fileUrl = fileUrl
    this.linkedResourceUrl = linkedResourceUrl
    this.selectedPageInfo.id = page?.id
    this.googleDriveMetadata = googleDriveMetadata
    this.contentType = contentType
    this.content = content
    let activeTab: AssetFormTab
    if (this.content) {
      activeTab = 'content'
    } else if (this.linkedResourceUrl || page?.id) {
      activeTab = 'linkedResourceUrl'
    } else {
      activeTab = 'upload'
    }
    this.activeTab = activeTab

    if (sharedDataStore.user.isFaasAdmin()) this.handlePageSearch(page?.title ?? '')
  }

  @computed get fileList(): UploadFile[] {
    return this.fileUrl
      ? [
          {
            uid: `${this.fileUrl}`,
            name: Asset.getFilename(this.fileUrl),
            status: 'done',
            url: this.fileUrl,
          },
        ]
      : []
  }

  handleSubmit = async (data: any) => {
    try {
      const isEdit = !!this.props.asset
      // We cannot pass asset directly to onSuccess, as the Library won't re-render due to the same reference for the updated asset
      const asset: Asset = this.props.asset ? Asset.fromData({ ...this.props.asset }) : new Asset()
      const { groupIds, hubIds, tags, customFields, file, sfMetadata, expiryDate, ...newData } = data

      await sharedAppStateStore.wrapAppLoading(
        asset.save(
          {
            ...newData,
            sfMetadata: clearFalseyValues(sfMetadata),
            fileUrl: this.fileUrl || null,
            linkedResourceUrl: this.linkedResourceUrl || null,
            content: this.content,
            contentType: this.contentType,
            pageId: this.selectedPageInfo.id || null,
            googleDriveMetadata: this.googleDriveMetadata,
            tags: tags || [],
            customFields,
            groupIds: groupIds || [],
            hubIds: hubIds || [],
            expiryDate: formatDateToPST(expiryDate),
          },
          true,
          true
        )
      )
      if (asset.isInReview()) {
        showNotification({ message: 'Your asset has been successfully created and is pending review' })
      } else {
        showNotification({
          message: `${truncate(asset.title, 70)} was ${isEdit ? 'updated' : 'created'} successfully!`,
          duration: 10,
          target: getFeedContentTargetLink(asset),
          clickToView: true,
        })
      }
      this.props.onSuccess(asset, true)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  handleUpload = async (file: File) => {
    try {
      const fileUrl = await sharedAppStateStore.wrapAppLoading(uploadToS3(file), 'Uploading asset...')
      this.googleDriveMetadata = {}
      this.cleanFilename = Asset.cleanFileName(file.name)
      this.onUploadComplete(file.type, fileUrl)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      return false
    }
  }

  handleFileRemove = () => {
    this.fileUrl = null
    this.contentType = ''
    this.googleDriveMetadata = {}
  }

  handleGoogleDriveFileSelected = async (doc: CallbackDoc) => {
    try {
      const fileData = await sharedAppStateStore.wrapAppLoading(
        Asset.transferDriveDocToS3(doc),
        'Transferring asset...'
      )
      this.googleDriveMetadata = doc
      this.cleanFilename = doc.name
      this.onUploadComplete(fileData.contentType, fileData.fileUrl)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onUploadComplete = (contentType: string, fileUrl: string) => {
    this.contentType = contentType
    this.fileUrl = fileUrl
    sharedAppStateStore.assetModalProps.isFileUploaded = true
    this.goToNextStep()
  }

  validateUpload = () => {
    this.formikRef.current.submitForm()
  }

  validateLink = () => {
    const linkedResourceUrl = this.formikRef?.current?.values?.['linkedResourceUrl']
    const pageId = this.formikRef?.current?.values?.['pageId']

    // If both pageId and linkedResourceUrl are filled or none of them is filled
    if ((!pageId && !linkedResourceUrl) || (pageId && linkedResourceUrl)) {
      let message = 'An asset can only contain either a link or a page.'
      if (!pageId && !linkedResourceUrl) message = 'Link or Page must be specified.'
      this.formikRef.current.setErrors({ pageId: message, linkedResourceUrl: message }, true)
    }
  }

  validateContent = () => {
    if (!this.formikRef?.current?.values?.['content']) {
      this.formikRef.current.setErrors({ content: 'This field is required' }, true)
    }
  }

  validateForm = () => {
    const formValues = [this.linkedResourceUrl, this.selectedPageInfo.id, this.fileUrl, this.content].filter(Boolean)
    if (formValues.length === 0) {
      switch (this.activeTab) {
        case 'upload':
          this.validateUpload()
          break
        case 'linkedResourceUrl':
          this.validateLink()
          break
        case 'content':
          this.validateContent()
          break
      }
      return false
    } else if (formValues.length === 1) {
      if (this.linkedResourceUrl && !isValidURL(this.linkedResourceUrl)) {
        // Normally, if the user fills out one field, we can proceed to the next modal.
        // However, if the filled field is linkedResourceUrl, we need to validate that it is a valid link.
        return false
      }
      return true
    } else if (formValues.length > 1) {
      if (formValues.length === 2 && this.linkedResourceUrl && this.selectedPageInfo.id) {
        // One edge case is that the user might not see the error if they filled out both the linkedResourceUrl and page fields in Link tab and then clicked the "Next" button in another tab (Upload/Content).
        // In that case, we need to switch to the Link tab so user can see the form error.
        this.activeTab = 'linkedResourceUrl'
        this.validateLink()
      } else {
        showNotification({
          message: 'Please select only one option: Upload, Link, or Content. You cannot select more than one',
          type: 'error',
        })
      }
      return false
    }
  }

  goToNextStep = () => {
    const valid = this.validateForm()
    if (!valid) return

    // Since user can click "Next" button in any tab, the last selected tab might not be the one that contains the form data.
    // When user returns to the first modal from the second modal(the metadata modal), the last selected tab must be the one containing the form data.
    if (this.fileUrl) {
      this.activeTab = 'upload'
    } else if (this.content) {
      this.activeTab = 'content'
    } else {
      this.activeTab = 'linkedResourceUrl'
    }
    this.showAssetMetadataForm = true
  }

  handlePageSearch = async (search = '') => {
    this.selectedPageInfo.pageName = search
    try {
      const { data } = await Page.list({ search })
      this.pageOptions = data.map((item) => ({ ...item, value: item.title, label: item.title }))
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined)
    }
  }

  handlePageSelected = (value: any, option: any) => {
    this.formikRef.current.setFieldValue('pageId', option.id)
    this.selectedPageInfo = { pageName: value, id: option.id }
  }

  handlePageCleared = () => {
    this.formikRef.current.setFieldValue('pageId', undefined)
    this.selectedPageInfo = { pageName: '', id: undefined }
  }

  handleContentChange = (data) => {
    this.content = data
    sharedAppStateStore.setAssetIsUpdated(data)
  }

  getInitialValues = () => {
    let {
      title,
      description,
      tags,
      sfMetadata,
      groupIds,
      hubIds,
      linkedResourceUrl,
      fileUrl,
      customFields,
      page,
      isInternal,
      preventDownload,
      preventDuplicate,
      expiryDate,
      content,
      thumbnailUrl,
    } = this.props.asset || {
      title: this.cleanFilename,
    }
    if (!groupIds?.length && !sharedDataStore.user.isPartOfManyGroups(true)) {
      groupIds = [sharedDataStore.user.defaultGroup.id]
    }

    return {
      title: title,
      description,
      content,
      tags,
      groupIds,
      hubIds,
      linkedResourceUrl,
      file: fileUrl,
      sfMetadata: {
        id: sfMetadata?.id,
        name: sfMetadata?.name,
        stageName: sfMetadata?.stageName,
      },
      customFields,
      pageId: page?.id,
      isInternal,
      preventDownload,
      preventDuplicate,
      expiryDate,
      thumbnailUrl,
      sourceTemplateId: undefined,
    }
  }

  handleTemplateSelected = async (template: Template) => {
    const templateAsset = template.learningContent as Asset
    this.formikRef.current.setFieldValue('title', templateAsset.title)
    this.formikRef.current.setFieldValue('description', templateAsset.description)
    this.formikRef.current.setFieldValue('sourceTemplateId', template.id)
    if (templateAsset.content) {
      this.activeTab = 'content'
      this.formikRef.current.setFieldValue('content', templateAsset.content)
      this.content = templateAsset.content
    } else if (templateAsset.page) {
      this.activeTab = 'linkedResourceUrl'
      const duplicatedPage = await sharedAppStateStore.wrapAppLoading(
        templateAsset.page.duplicate(
          false,
          templateAsset.page.publishedAt ? { publishedAt: new Date().toISOString() } : {}
        ),
        'Duplicating Template Page...'
      )
      this.selectedPageInfo = { pageName: duplicatedPage.title, id: duplicatedPage.id }
      this.formikRef.current.setFieldValue('pageId', duplicatedPage.id)
    } else if (templateAsset.linkedResourceUrl) {
      this.activeTab = 'linkedResourceUrl'
      this.formikRef.current.setFieldValue('linkedResourceUrl', templateAsset.linkedResourceUrl)
      this.linkedResourceUrl = templateAsset.linkedResourceUrl
    } else {
      await templateAsset.duplicate(
        `Creating Asset from template: ${template.name}...`,
        'Asset successfully created from template!'
      )
      sharedAppStateStore.assetModalProps = undefined
    }
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText
          heading={this.props.asset ? 'Edit Asset Metadata' : 'Create Asset'}
          subHeading={
            this.showAssetMetadataForm ? 'Add additional metadata about the asset to help find it easily.' : undefined
          }
        />
        <Formik
          initialValues={this.getInitialValues()}
          initialTouched={{ pageId: true, linkedResourceUrl: true, content: true }}
          onSubmit={this.handleSubmit}
          innerRef={this.formikRef}
          enableReinitialize
        >
          <Form>
            {!this.showAssetMetadataForm ? (
              <>
                <ContainerDiv>
                  {!this.props.asset && (
                    <ContainerDiv marginBottom>
                      <UseTemplateInfoBox contentType="asset" handleTemplateSelected={this.handleTemplateSelected} />
                    </ContainerDiv>
                  )}
                  <Tabs
                    activeKey={this.activeTab}
                    onChange={(key: AssetFormTab) => (this.activeTab = key)}
                    items={[
                      {
                        key: 'upload',
                        label: 'Upload',
                        children: (
                          <>
                            <FjFormItem
                              fieldtitle="Select an Asset to Upload"
                              name="file"
                              validate={combineValidations(isRequired, isFile)}
                            >
                              <FjDragUploader
                                name="file"
                                beforeUpload={(file) => this.handleUpload(file as File)}
                                fileList={this.fileList}
                                onRemove={this.handleFileRemove}
                              />
                            </FjFormItem>
                            <Divider style={DIVIDER_STYLES}>
                              <FjText fontSize="medium" fontWeight="bold" marginBottom color={Colors.abbey}>
                                Or
                              </FjText>
                            </Divider>
                            <GoogleDriveButton
                              disabled={process.env.REACT_APP_GOOGLE_DRIVE_ENABLED !== 'true'}
                              handleFileSelected={this.handleGoogleDriveFileSelected}
                            />
                          </>
                        ),
                      },
                      {
                        key: 'linkedResourceUrl',
                        label: 'Link',
                        children: (
                          <ContainerDiv width="100%" textAlign="right">
                            <FjFormItem fieldtitle="Link" name="linkedResourceUrl" validate={isURI}>
                              <FjInput
                                name="linkedResourceUrl"
                                placeholder="Paste link here"
                                onChange={(e) => (this.linkedResourceUrl = e.target.value)}
                              />
                            </FjFormItem>
                            {sharedDataStore.user.isFaasAdmin() ? (
                              <>
                                <Divider style={DIVIDER_STYLES}>
                                  <FjText fontSize="medium" fontWeight="bold" marginBottom color={Colors.abbey}>
                                    Or
                                  </FjText>
                                </Divider>
                                <FjFormItem fieldtitle="Page" name="pageId">
                                  <ContainerDiv
                                    textAlign="left"
                                    display="flex"
                                    justifyContent="space-between"
                                    gap="5px"
                                  >
                                    <FJStyledAutoComplete
                                      name="pageId"
                                      placeholder="Select Page"
                                      allowClear
                                      value={this.selectedPageInfo.pageName}
                                      onSearch={this.handlePageSearch}
                                      onSelect={this.handlePageSelected}
                                      onClear={this.handlePageCleared}
                                      options={this.pageOptions}
                                    >
                                      <FJStyledInput name="pageId" />
                                    </FJStyledAutoComplete>
                                    <DefaultButton
                                      style={{ minWidth: '110px' }}
                                      disabled={!this.selectedPageInfo.id}
                                      buttonType="tertiary"
                                      title="View Page"
                                      clicked={() =>
                                        window.open(Page.getFullPageUrl(this.selectedPageInfo.id), '_blank')
                                      }
                                    />
                                  </ContainerDiv>
                                </FjFormItem>
                              </>
                            ) : null}
                          </ContainerDiv>
                        ),
                      },
                      {
                        key: 'content',
                        label: 'Content',
                        children: (
                          <ContainerDiv textAlign="right">
                            <FjFormItem className="content-editor" name="content">
                              <Suspense fallback={<Loader />}>
                                <FJCKEditor
                                  placeholder=""
                                  name="content"
                                  showRecordVideoBtn
                                  includeMentions
                                  handleChange={this.handleContentChange}
                                />
                              </Suspense>
                            </FjFormItem>
                          </ContainerDiv>
                        ),
                      },
                    ]}
                  />
                </ContainerDiv>
                <ContainerDiv textAlign="right">
                  <DefaultButton
                    buttonType="primary"
                    title="Next"
                    clicked={this.goToNextStep}
                    style={{ marginTop: '10px' }}
                  />
                </ContainerDiv>
              </>
            ) : (
              <>
                <ContainerDiv
                  marginBottom="24px"
                  textAlign="left"
                  alignItems="center"
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  padding="16px 24px"
                  borderRadius={8}
                  backgroundColor={Colors.zumthor}
                >
                  <ContainerDiv width="100%" display="flex" alignItems="center" maxWidth="90%" className="no-margin">
                    <CheckCircle color={Colors.shark} size={20} style={{ marginRight: '12px', minWidth: 20 }} />
                    <Typography.Paragraph
                      ellipsis={{ rows: 1 }}
                      style={{ fontSize: '14px', fontWeight: 700, marginBottom: 0 }}
                      color={Colors.shark}
                    >
                      {this.fileUrl ? (
                        Asset.getFilename(this.fileUrl)
                      ) : this.linkedResourceUrl ? (
                        this.linkedResourceUrl
                      ) : this.selectedPageInfo.pageName ? (
                        this.selectedPageInfo.pageName
                      ) : (
                        <HTMLExpandablePreview
                          html={this.content}
                          parserOptions={{
                            showEmbedAsLink: true,
                            showMediaAsLink: true,
                            minimizeHeadings: true,
                          }}
                          rows={1}
                          expandable={false}
                        />
                      )}
                    </Typography.Paragraph>
                  </ContainerDiv>
                  <DefaultButton
                    buttonType="secondary"
                    title="Edit"
                    clicked={() => (this.showAssetMetadataForm = false)}
                  />
                </ContainerDiv>
                <FjFormItem name="title" fieldtitle="Title*" validate={isRequired}>
                  <FjInput
                    placeholder="Title"
                    name="title"
                    onChange={(e) => sharedAppStateStore.setAssetIsUpdated(e.target.value)}
                  />
                </FjFormItem>
                <SfMetadataFormFields
                  handleSelect={(_value: any, option: any) =>
                    this.formikRef.current.setFieldValue('sfMetadata', {
                      id: option.id,
                      name: option.name,
                      stageName: option.stageName,
                    })
                  }
                  handleClear={() => this.formikRef.current.setFieldValue('sfMetadata', null)}
                  expanded={!!this.props.asset?.sfMetadata}
                />

                <FjMemoFormItem className="description-editor" name="description" fieldtitle="Description">
                  <Suspense fallback={<Loader />}>
                    <FJCKEditor
                      placeholder="Enter a description (optional)"
                      name="description"
                      showRecordVideoBtn
                      includeMentions
                      handleChange={(data) => sharedAppStateStore.setAssetIsUpdated(data)}
                    />
                  </Suspense>
                </FjMemoFormItem>
                <Row gutter={16}>
                  {sharedDataStore.user.isPartOfManyGroups(true) ? (
                    <Col lg={12} md={24}>
                      <FjFormItem name="groupIds" fieldtitle="Groups*" validate={isRequired}>
                        <GroupSelector name="groupIds" excludePublicUserGroup />
                      </FjFormItem>
                    </Col>
                  ) : null}
                  <Col lg={12} md={24}>
                    <TagSelector showAITooltip />
                  </Col>
                </Row>
                <ExpirationDateField />
                <CustomMetadataFields showAITooltip />
                {sharedDataStore.user.isFaasAdmin() ? (
                  <FjFormItem name="hubIds" fieldtitle="Hubs">
                    <HubSelector name="hubIds" />
                  </FjFormItem>
                ) : null}
                <Row gutter={16}>
                  <Col sm={8} xs={24}>
                    <InternalUseField />
                  </Col>
                  <Col sm={8} xs={24}>
                    <PreventDownloadField />
                  </Col>
                  <Col sm={8} xs={24}>
                    <PreventDuplicateField />
                  </Col>
                </Row>
                <FjFormItem name="thumbnailUrl" fieldtitle="Thumbnail">
                  <UploadThumbnailButton name="thumbnailUrl" />
                </FjFormItem>
                <FjFormItem name="sourceTemplateId" style={{ display: 'none' }}>
                  <FjInput name="sourceTemplateId" />
                </FjFormItem>
                <FormActionButtons submitButtonLabel="Save" />
              </>
            )}
          </Form>
        </Formik>
      </ContainerDiv>
    )
  }
}
