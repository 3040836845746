import { Row, Col, Divider } from 'antd'
import { observable, makeObservable, computed } from 'mobx'
import { observer } from 'mobx-react'
import React, { CSSProperties } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  ContainerDiv,
  EditButton,
  FjText,
  Loader,
  CollapsibleMenuButton,
  FJStyledInput,
  DuplicateButton,
  FjCard,
  pageTitleFontSize,
} from 'src/components/Common'
import { FeedPage } from 'src/components/Page/Page'
import { Colors } from 'src/constants/colors'
import { Page } from 'src/models/Page'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { CategoryItem } from 'src/components/Common/Tab'
import { getIDFromPath } from 'src/utils/urlParams'
import { Paths } from 'src/constants/navigation'
import { ViewTracker } from 'src/components/Feed/FeedCard'
import { FeedHTMLBodyParser } from 'src/components/Common/HTMLExpandablePreview'
import { mobileQuery } from 'src/pages/public/common'
import { useMediaQuery } from 'react-responsive'
import { Formik } from 'formik'
import { Search } from 'react-feather'
import { ContentTypeIcon } from 'src/utils/content'
import { MAIN_CONTENT_COL_PROPS, SIDEBAR_COL_PROPS } from 'src/components/Page/ContentWrapper'
import { ViewState, ViewWrapper } from 'src/components/Common/ViewWrapper'
import BreadcrumbBar from 'src/components/Common/BreadcrumbBar'

export const PageRenderer: React.FC<{
  page: Page
  trackingSource?: string
  trackViews?: boolean
  style?: CSSProperties
}> = ({ page, trackingSource = 'page', trackViews = true, style }) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <>
      {trackViews ? <ViewTracker obj={page} source={trackingSource} observerThreshold={0} /> : null}
      <FjCard
        id={page.getTrackingElementId()}
        backgroundColor="white"
        textAlign="left"
        key={page.id}
        style={{ ...style }}
      >
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col>
            <FjText fontSize="20px" fontWeight="semi-bold" color={Colors.chambray} textAlign="left">
              {page.title}
            </FjText>
          </Col>
          <Col style={{ marginLeft: 'auto' }}>
            <ContainerDiv display="flex" alignItems="center" gap="10px">
              <DuplicateButton obj={page} color={Colors.cornflowerBlue} />
              <EditButton obj={page} color={Colors.cornflowerBlue} />
            </ContainerDiv>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px',
            width: '100%',
          }}
        >
          <Col style={{ width: isMobile ? '100%' : '20rem', wordWrap: 'break-word', flex: 3, overflow: 'auto' }}>
            {FeedHTMLBodyParser(page.content)}
          </Col>
        </Row>
      </FjCard>
    </>
  )
}

@observer
export class PageView extends React.Component<RouteComponentProps> {
  @observable pages: Page[] = []
  @observable page: Page
  @observable pageId: string
  @observable isPagesLoading = false
  @observable collapsed = true
  @observable searchTerm = ''
  @observable viewState: ViewState = 'initialLoad'

  @computed get filteredPages() {
    return this.pages.filter((page) => page.title.toLowerCase().includes(this.searchTerm.toLowerCase()))
  }

  getIdAndPage = async () => {
    const pageId = getIDFromPath(1)
    if (pageId) {
      this.pageId = pageId
      this.getPage()
    } else {
      await this.getPages()
      if (this.pages.length) sharedAppStateStore.navigate(Paths.getPagePath({ id: this.pages[0].id }), true)
    }
  }

  getPages = async () => {
    if (this.pages.length) return
    this.isPagesLoading = true
    try {
      const { data } = await Page.list({ page_size: 200, sort_by: 'alphabetical' })
      this.pages = data
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isPagesLoading = false
    }
  }

  getPage = async () => {
    try {
      this.viewState = 'loading'
      this.page = await Page.get(this.pageId)
      this.viewState = 'idle'
    } catch (err) {
      if (err.response?.status === 404) this.viewState = 'notFound'
      else {
        this.viewState = 'error'
        sharedAppStateStore.handleError(err)
      }
    }
  }

  constructor(props: RouteComponentProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    this.getIdAndPage()
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.match.params !== prevProps.match.params) {
      this.getIdAndPage()
    }
  }

  handleCollapse = async () => {
    this.collapsed = !this.collapsed
    this.getPages()
  }

  handleClickMenu = (id: string) => {
    if (sharedAppStateStore.isMobile) this.collapsed = true
    sharedAppStateStore.navigate(Paths.getPagePath({ id }))
  }

  render() {
    return (
      <FeedPage title={`Page: ${this.page?.title}`}>
        <BreadcrumbBar />
        <Row gutter={10} style={{ marginBottom: '10px' }}>
          <ContainerDiv textAlign="left" display="flex" alignItems="center" gap={8}>
            <ContentTypeIcon contentType="page" size={sharedAppStateStore.isMobile ? 24 : 40} color={Colors.chambray} />
            <FjText color={Colors.chambray} fontSize={pageTitleFontSize} fontWeight="semi-bold">
              Pages
            </FjText>
          </ContainerDiv>
        </Row>
        <Row>
          <Col span={24} style={{ marginBottom: '0.5rem' }}>
            <CollapsibleMenuButton tooltipTitle="Page List" collapsed={this.collapsed} clicked={this.handleCollapse} />
          </Col>
          <Col {...(this.collapsed ? { span: 0 } : SIDEBAR_COL_PROPS)}>
            {this.isPagesLoading ? (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Loader />
              </div>
            ) : (
              <ContainerDiv paddingHorizontal={!sharedAppStateStore.isMobile}>
                <Formik initialValues={{}} onSubmit={() => {}}>
                  <ContainerDiv
                    backgroundColor={Colors.hawkesBlue}
                    padding="5px"
                    marginBottom="5px"
                    borderRadius="13px"
                  >
                    <FJStyledInput
                      prefix={<Search size={16} />}
                      name="keyword"
                      placeholder="Search Pages"
                      value={this.searchTerm}
                      onChange={(e) => (this.searchTerm = e.target.value)}
                    />
                  </ContainerDiv>
                </Formik>
                <Divider style={{ margin: '6px 0' }} />
                {this.filteredPages.map((page) => (
                  <CategoryItem
                    key={`categories-${page.id}`}
                    category={page.title}
                    active={page.id === this.pageId}
                    onClick={() => this.handleClickMenu(page.id)}
                  />
                ))}
              </ContainerDiv>
            )}
          </Col>
          <Col {...(this.collapsed ? { xs: 24, sm: 24, md: 24, lg: 24 } : MAIN_CONTENT_COL_PROPS)}>
            <ViewWrapper viewState={this.viewState} loaderProps={{ containerStyle: { height: 'unset' } }}>
              {this.viewState === 'idle' ? <PageRenderer page={this.page} /> : null}
            </ViewWrapper>
          </Col>
        </Row>
      </FeedPage>
    )
  }
}
