import { Tooltip } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { RouteComponentProps } from 'react-responsive'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { DefaultLink, ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { ArrowLeft } from 'react-feather'
import { sharedCanvasManager } from 'src/utils/Canvas'

export interface BreadcrumbType {
  breadcrumbName: string
  path: string
  contentType: 'course' | 'hub' | 'learningpath'
  selectedLearningPathContentId: string
}

@observer
class BreadcrumbBar extends React.Component<RouteComponentProps> {
  handleBreadcrumbClick = (route: BreadcrumbType) => (e: React.MouseEvent) => {
    e.preventDefault()
    sharedAppStateStore.navigate(route.path, false, { fromBreadcrumb: true })
  }

  render() {
    if (sharedAppStateStore.routes.length === 0) {
      if (sharedCanvasManager.sr)
        return (
          <ContainerDiv
            display="flex"
            alignItems="center"
            gap={8}
            cursor="pointer"
            marginBottom
            onClick={() => sharedAppStateStore.navigateBack()}
          >
            <ArrowLeft color={Colors.tapa} size={16} />
            <FjText fontSize="14px" fontWeight="bold500" color={Colors.tapa}>
              Back
            </FjText>
          </ContainerDiv>
        )
      return null
    }
    return (
      <ContainerDiv
        borderRadius="5px"
        textAlign="left"
        display="flex"
        width="100%"
        overflow="hidden"
        marginBottom
        gap={4}
      >
        {sharedAppStateStore.routes.map((route, i) => {
          const isLast = sharedAppStateStore.routes.length - 1 === i
          return (
            <ContainerDiv display="flex" alignItems="center" key={i} gap={4}>
              <Tooltip placement="bottom" title={route.breadcrumbName} key={JSON.stringify(route)}>
                <DefaultLink
                  to="#"
                  color="navyBlue"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    minWidth: '30px',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: isLast ? Colors.tapa : Colors.cottonSeed,
                  }}
                  onClick={this.handleBreadcrumbClick(route)}
                >
                  {route.breadcrumbName.length > 35
                    ? `${route.breadcrumbName.substring(0, 35)}...`
                    : route.breadcrumbName}
                </DefaultLink>
              </Tooltip>
              {!isLast && (
                <FjText fontSize="16px" fontWeight="bold500" color={Colors.cottonSeed}>
                  /
                </FjText>
              )}
            </ContainerDiv>
          )
        })}
      </ContainerDiv>
    )
  }
}

export default BreadcrumbBar
