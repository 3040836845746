import React, { Component } from 'react'
import { Divider } from 'antd'
import { observer } from 'mobx-react'
import {
  FormHeaderText,
  ContainerDiv,
  FjCheckboxGroup,
  FjText,
  FjFormItem,
  DefaultButton,
  Loader,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { observable, computed, makeObservable } from 'mobx'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { APIProvider } from 'src/network/APIProvider'
import { showNotification } from 'src/hoc/Notification'
import { sharedDataStore } from 'src/store/DataStore'
import {
  LearningContent,
  fetchContent,
  getContentCategoryTitle,
  getFeedContentClass,
  getFeedContentTargetLink,
  getFeedContentType,
} from 'src/utils/content'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { LearningPath } from 'src/models/LearningPath'
import { Hub } from 'src/models/Hub'
import { pluralize } from 'src/utils/format'
import { sharedQueryClient } from 'src/store/QueryClient'

const DIVIDER_STYLES: React.CSSProperties = { margin: '2px 0px', height: '4px' }

export type ContainerContentType = 'learningpath' | 'playlist' | 'hub'

const CONTAINER_TYPE_TO_CLASS = {
  learningpath: LearningPath,
  playlist: FeedPlaylist,
  hub: Hub,
}

export interface AddContentToContentContainerFormProps {
  obj: LearningContent
  containerType: ContainerContentType
  onSuccess(): void
  onCancel?(): void
}

@observer
export class AddContentToContentContainerForm extends Component<AddContentToContentContainerFormProps> {
  @observable contentContainers: (FeedPlaylist | LearningPath | Hub)[] = []
  @observable isLoading: boolean = false
  @observable selectedContainerIds: string[] = []
  @observable pageNum: number = 1
  @observable hasNextPage: boolean = false
  @observable preselectedContainerIds = []

  constructor(props: AddContentToContentContainerFormProps) {
    super(props)
    makeObservable(this)
  }

  @computed get containerOptionsMap() {
    return new Map(
      this.contentContainers.map((container) => [
        container.id,
        <FjText display="inlineblock" textAlign="left" wordBreak="break-word" color={Colors.abbey}>
          {container.title}
        </FjText>,
      ])
    )
  }

  async componentDidMount() {
    await this.fetchPreSelectedContainers()
    this.fetchData()
  }

  fetchPreSelectedContainers = async () => {
    const { data } = await fetchContent(this.props.containerType, {
      contains_learning_content: this.props.obj.learningContentId,
      fields: 'id',
      page_size: 1000,
      ...(sharedDataStore.user.isFaasAdmin() ? {} : { author_id: sharedDataStore.user.id }),
    })
    this.preselectedContainerIds = data.map(({ id }) => id)
  }

  fetchData = async () => {
    try {
      if (this.pageNum === 1) this.isLoading = true
      const { data, hasNextPage } = await fetchContent(this.props.containerType, {
        page: this.pageNum,
        sort_by: 'alphabetical',
        ...(sharedDataStore.user.isFaasAdmin() ? {} : { author_id: sharedDataStore.user.id }),
      })
      this.contentContainers = [...this.contentContainers, ...data].filter((obj) => obj.id !== this.props.obj.id)
      this.hasNextPage = hasNextPage
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isLoading = false
    }
  }

  fetchMoreData = () => {
    this.pageNum = this.pageNum + 1
    this.fetchData()
  }

  handleSubmit = async (data: any) => {
    const { containerIds } = data
    const { obj, containerType } = this.props

    try {
      this.isLoading = true
      for (const containerId of containerIds) {
        await APIProvider.addContentToContainer(
          CONTAINER_TYPE_TO_CLASS[containerType],
          containerId,
          obj.id,
          getFeedContentType(obj)
        )
      }
      const containerDisplayName = pluralize(
        getContentCategoryTitle(containerType)
          .slice(0, -1) // remove s
          .toLowerCase(),
        this.selectedContainerIds.length
      )
      showNotification({
        message: `${this.props.obj.title} has been successfully added to the selected ${containerDisplayName}!`,
      })
      this.props.onSuccess()
      sharedQueryClient.invalidateQueries({
        queryKey: [getFeedContentClass(containerType).apiEndpoint],
        refetchType: 'none',
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isLoading = false
    }
  }

  handleCreateNewContainer = () => {
    sharedAppStateStore.createContentContainerSeed = this.props.obj
    sharedAppStateStore.addContentToContainerModalProps = undefined
    sharedAppStateStore.navigate(
      getFeedContentTargetLink(new CONTAINER_TYPE_TO_CLASS[this.props.containerType](), { isAuthoring: true })
    )
  }

  render() {
    const containerDisplayName = getContentCategoryTitle(this.props.containerType)
    return (
      <ContainerDiv>
        <FormHeaderText heading={`Add to ${containerDisplayName}`} />
        {this.isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{ containerIds: this.preselectedContainerIds }}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            <Form>
              <FjFormItem
                fieldtitle={`Select ${containerDisplayName}:`}
                name="containerIds"
                style={{ marginBottom: 0 }}
              >
                <ContainerDiv
                  id="addContentToContainerFormScrollableDiv"
                  maxHeight={sharedAppStateStore.isMobile ? '100%' : '20rem'}
                  overflow={this.isLoading ? 'hidden' : 'auto'}
                  textAlign="left"
                >
                  <InfiniteScroll
                    dataLength={this.contentContainers.length}
                    next={this.fetchMoreData}
                    hasMore={this.hasNextPage}
                    style={{ overflow: 'hidden' }}
                    loader={<Loader />}
                    scrollableTarget="addContentToContainerFormScrollableDiv"
                  >
                    <FjCheckboxGroup
                      name="containerIds"
                      optionsMap={this.containerOptionsMap}
                      checkboxStyle={{ width: '100%' }}
                      // We have to maintain this.selectedPlaylistIds (an observable), as we are unable
                      // to use <InfiniteScroll/> within ({values})=>(<></>) of Formik
                      // due to no re-render on nextPageLoad, and we need the list of selectedIds for the conditions
                      // to enable/disable Create New Playlist / Save buttons
                      onChange={(selectedIds: CheckboxValueType[]) =>
                        (this.selectedContainerIds = selectedIds.map((id) => id.toString()))
                      }
                      disabledOptionKeys={this.preselectedContainerIds}
                    />
                  </InfiniteScroll>
                </ContainerDiv>
              </FjFormItem>
              <Divider style={DIVIDER_STYLES} />
              <ContainerDiv textAlign="right" marginTop="10px">
                <DefaultButton
                  title={`Create New ${containerDisplayName.slice(0, -1)}`} // remove s
                  buttonType="secondary"
                  style={{ marginRight: '20px' }}
                  clicked={this.handleCreateNewContainer}
                  disabled={this.selectedContainerIds.length > 0}
                />
                <DefaultButton
                  title="Save"
                  buttonType="primary"
                  type="submit"
                  disabled={this.selectedContainerIds.length === 0}
                />
              </ContainerDiv>
            </Form>
          </Formik>
        )}
      </ContainerDiv>
    )
  }
}
