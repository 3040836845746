import { Formik } from 'formik'
import React from 'react'
import { ContainerDivProps, ContainerDiv, FjCheckBox } from 'src/components/Common'
import HTMLExpandablePreview from 'src/components/Common/HTMLExpandablePreview'
import { TaskModal } from 'src/components/Learn/TaskModal'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'

interface LearningObjectPreviewCardProps {
  objectId: string
  selected?: boolean
  inProgress: boolean
  isCompleted: boolean
  cursorStyle?: string
  onClickHandler: () => void
  checkBoxClickhandler: () => void
  locked: boolean
  contentTypeTitle: string
  checkboxDisabled: boolean
  imgComponent: React.ReactNode
  title: string
  style?: ContainerDivProps
}

export const LearningObjectPreviewCard: React.FC<LearningObjectPreviewCardProps> = ({
  objectId,
  selected,
  isCompleted = false,
  cursorStyle = 'normal',
  onClickHandler,
  checkBoxClickhandler = () => {},
  locked,
  contentTypeTitle,
  checkboxDisabled,
  imgComponent,
  title,
  style,
}) => {
  const showTaskViewModal = (e: React.MouseEvent) => {
    e.preventDefault()
    sharedAppStateStore.modalProps = {
      title: 'Task',
      children: <TaskModal title={title} isCompleted={isCompleted} handleClickComplete={checkBoxClickhandler} />,
    }
  }

  return (
    <ContainerDiv
      borderBottom={`solid 1px ${Colors.sharkOpacity10}`}
      borderLeft={selected ? `solid 6px ${Colors.texasRose}` : undefined}
      padding="10px 18px 18px 18px"
      display="flex"
      backgroundColor={Colors.white}
      cursor={cursorStyle}
      onClick={onClickHandler}
      {...style}
      gap={16}
    >
      <Formik initialValues={{}} onSubmit={() => {}}>
        <FjCheckBox
          name="content-complete-check"
          size="large"
          value={objectId}
          onChange={checkBoxClickhandler}
          checked={isCompleted}
          disabled={checkboxDisabled || locked}
        />
      </Formik>
      <ContainerDiv textAlign="left" display="flex" flexDirection="column" alignItems="start" gap="10px">
        {imgComponent}
        <ContainerDiv
          onClick={(e) => (contentTypeTitle === 'task' && !locked ? showTaskViewModal(e) : null)}
          textAlign="left"
          overflowWrap="anywhere"
          color={locked ? Colors.cottonSeed : Colors.black}
        >
          <HTMLExpandablePreview
            rows={2}
            html={title}
            expandable={false}
            parserOptions={{ showEmbedAsLink: true, showMediaAsLink: true }}
          />
        </ContainerDiv>
      </ContainerDiv>
    </ContainerDiv>
  )
}
