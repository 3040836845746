import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getFeedContentClass, LearningContent } from 'src/utils/content'
import {
  ContainerDiv,
  FjFormItem,
  FormActionButtons,
  FormHeaderText,
  GroupSelector,
  HubSelector,
  TagSelector,
} from 'src/components/Common'
import { Form, Formik, FormikProps } from 'formik'
import { CustomMetadataFields } from 'src/components/Feed/CustomMetadataFields'
import { Col, Row } from 'antd'
import { clearFalseyValues, deepEquals, formatDateToPST, pluralize, removeDuplicates } from 'src/utils/format'
import { Course } from 'src/models/Course'
import { EnforceMediaCompletionField, RequireSequentialCompletionField } from 'src/pages/CourseAuthoring'
import { Asset } from 'src/models/Asset'
import { InternalUseField, PreventDownloadField, PreventDuplicateField } from 'src/components/Feed/AssetForm'
import { ExpirationDateField } from 'src/components/Feed/ExpirationDateField'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { SfMetadataFormFields } from 'src/components/Feed/SfMetadataFormFields'
import { LearningPath } from 'src/models/LearningPath'
import moment from 'moment'

export interface IBulkEditFormModalProps {
  learningContents: LearningContent[]
  onSuccess: (objs: LearningContent[]) => void
  onCancel: () => void
}

export const BulkEditFormModal: React.FC<IBulkEditFormModalProps> = ({ onSuccess, onCancel, learningContents }) => {
  const [enforceMediaCompletionIndeterminate, setEnforceMediaCompletionIndeterminate] = useState(false)
  const [requireSequentialCompletionIndeterminate, setRequireSequentialCompletionIndeterminate] = useState(false)
  const [internalUseIndeterminate, setInternalUseIndeterminate] = useState(false)
  const [preventDuplicateIndeterminate, setPreventDuplicateIndeterminate] = useState(false)
  const [preventDownloadIndeterminate, setPreventDownloadIndeterminate] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const formRef = useRef<FormikProps<any>>(null)

  const fieldsToShow = useMemo(() => {
    const editFieldArrays = learningContents.map((lc) => lc.getBulkEditFields())
    // only show fields that can be edited in all selected lcs
    return editFieldArrays[0].filter((field) => editFieldArrays.every((fields) => fields.includes(field)))
  }, [learningContents])

  const handleSfMetadataSelected = useCallback(
    (_value: any, option: any) => {
      formRef.current.setFieldValue('sfMetadata', { id: option.id, name: option.name, stageName: option.stageName })
    },
    [formRef]
  )

  const clearSfMetadata = useCallback(() => formRef.current.setFieldValue('sfMetadata', null), [formRef])

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const responses = await sharedAppStateStore.wrapAppLoading(
          Promise.all(
            learningContents.map((lc) => {
              const newData = { ...data }

              if ('sfMetadata' in lc && newData['sfMetadata'])
                newData['sfMetadata'] = clearFalseyValues({
                  ...(lc?.sfMetadata?.formatMetadataForSave() || {}),
                  ...newData['sfMetadata'],
                })

              if ('groupIds' in lc && newData['groupIds'])
                newData['groupIds'] = removeDuplicates([...lc.groupIds, ...newData['groupIds']])

              if ('tags' in lc && newData['tags']) newData['tags'] = removeDuplicates([...lc.tags, ...newData['tags']])

              if ('hubIds' in lc && newData['hubIds'])
                newData['hubIds'] = removeDuplicates([...lc.hubIds, ...newData['hubIds']])

              if (newData['expiryDate']) newData['expiryDate'] = formatDateToPST(newData['expiryDate'])

              if ('customFields' in lc && newData['customFields'])
                newData['customFields'] = clearFalseyValues({ ...(lc?.customFields || {}), ...newData['customFields'] })

              // remove fields that haven't changed
              const dataToSave = Object.keys(newData).reduce((acc, key) => {
                if (moment.isMoment(lc[key]) && deepEquals(formatDateToPST(lc[key]), newData[key])) return acc

                if (!deepEquals(newData[key], lc[key])) acc[key] = newData[key]
                return acc
              }, {})

              if (Object.keys(dataToSave).length) return lc.save(dataToSave)
              return null
            })
          ),
          'Updating...'
        )

        // have to create new instances of objects so that table data is updated
        const updatedLearningContents = responses
          .filter(Boolean)
          .map((res, i) => getFeedContentClass(learningContents[i].learningContentType).fromData(res.data))
        onSuccess(updatedLearningContents)
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    },
    [learningContents, onSuccess]
  )

  useEffect(() => {
    const courses = learningContents.filter((lc) => lc instanceof Course)
    const coursesAndLearningPaths = learningContents.filter((lc) => lc instanceof Course || lc instanceof LearningPath)
    const assets = learningContents.filter((lc) => lc instanceof Asset)
    const initialValues = {}

    /*
    e.g. if all selected courses have `enforceMediaCompletion` on then there is no issue since the checkbox
    will be filled. The same is true if it's off since the only thing to do is turn it on. But if some 
    have it on and some have it off it is unclear what the action does so:

    1. I added logic to figure out if there are differing values and if that is the case we show the 
    indeterminate state (half filled in checkbox) to indicate this to the user
    2. In the indeterminate state we also exclude this value from `initialValues` so if the checkbox is never 
    touched we don't send this field in the request.
    */

    const enforceMediaCompletionValues = removeDuplicates(
      courses.map((course) => (course as Course).enforceMediaCompletion)
    )
    setEnforceMediaCompletionIndeterminate(enforceMediaCompletionValues.length > 1)
    if (enforceMediaCompletionValues.length === 1)
      initialValues['enforceMediaCompletion'] = enforceMediaCompletionValues[0]

    const requireSequentialCompletionValues = removeDuplicates(
      coursesAndLearningPaths.map((courseOrLp) => (courseOrLp as Course).requireSequentialCompletion)
    )
    setRequireSequentialCompletionIndeterminate(requireSequentialCompletionValues.length > 1)
    if (requireSequentialCompletionValues.length === 1)
      initialValues['requireSequentialCompletion'] = requireSequentialCompletionValues[0]

    const internalUseValues = removeDuplicates(assets.map((asset) => (asset as Asset).isInternal))
    setInternalUseIndeterminate(internalUseValues.length > 1)
    if (internalUseValues.length === 1) initialValues['isInternal'] = internalUseValues[0]

    const preventDuplicateValues = removeDuplicates(assets.map((asset) => (asset as Asset).preventDuplicate))
    setPreventDuplicateIndeterminate(preventDuplicateValues.length > 1)
    if (preventDuplicateValues.length === 1) initialValues['preventDuplicate'] = preventDuplicateValues[0]

    const preventDownloadValues = removeDuplicates(assets.map((asset) => (asset as Asset).preventDownload))
    setPreventDownloadIndeterminate(preventDownloadValues.length > 1)
    if (preventDownloadValues.length === 1) initialValues['preventDownload'] = preventDownloadValues[0]

    // if all expiryDates are the same we can set initial value
    const expiryDateValues = removeDuplicates(
      learningContents.map((lc) => ('expiryDate' in lc ? lc.expiryDate?.toISOString() : undefined))
    )
    if (expiryDateValues.length === 1) initialValues['expiryDate'] = learningContents[0]['expiryDate']

    if (Object.keys(initialValues).length) setInitialValues(initialValues)
  }, [learningContents])

  return (
    <ContainerDiv>
      <FormHeaderText heading={`Bulk Edit (${learningContents.length})`} />
      <Formik innerRef={formRef} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
        <Form>
          {fieldsToShow.includes('sfMetadata') && (
            <SfMetadataFormFields handleSelect={handleSfMetadataSelected} handleClear={clearSfMetadata} />
          )}
          <Row gutter={16}>
            <Col md={24} lg={12}>
              <FjFormItem name="groupIds" fieldtitle="Groups">
                <GroupSelector name="groupIds" placeholder="Add groups" />
              </FjFormItem>
            </Col>
            {fieldsToShow.includes('tags') && (
              <Col md={24} lg={12}>
                <TagSelector />
              </Col>
            )}
            {fieldsToShow.includes('hubs') && (
              <Col md={24} lg={12}>
                <FjFormItem name="hubIds" fieldtitle="Hubs">
                  <HubSelector name="hubIds" />
                </FjFormItem>
              </Col>
            )}
            {fieldsToShow.includes('expiryDate') && (
              <Col md={24} lg={12}>
                <ExpirationDateField />
              </Col>
            )}
          </Row>
          {fieldsToShow.includes('customFields') && <CustomMetadataFields />}
          <Row gutter={16}>
            {fieldsToShow.includes('enforceMediaCompletion') && (
              <Col md={24} lg={12}>
                <EnforceMediaCompletionField
                  checkboxProps={{
                    indeterminate: enforceMediaCompletionIndeterminate,
                    onChange: () => setEnforceMediaCompletionIndeterminate(false),
                  }}
                />
              </Col>
            )}
            {fieldsToShow.includes('requireSequentialCompletion') && (
              <Col md={24} lg={12}>
                <RequireSequentialCompletionField
                  checkboxProps={{
                    indeterminate: requireSequentialCompletionIndeterminate,
                    onChange: () => setRequireSequentialCompletionIndeterminate(false),
                  }}
                />
              </Col>
            )}
          </Row>
          <Row>
            {fieldsToShow.includes('isInternal') && (
              <Col md={24} lg={8}>
                <InternalUseField
                  tooltip="By checking this box users will be unable to create share links for the selected asset(s)."
                  checkboxProps={{
                    indeterminate: internalUseIndeterminate,
                    onChange: () => setInternalUseIndeterminate(false),
                  }}
                />
              </Col>
            )}
            {fieldsToShow.includes('preventDownload') && (
              <Col md={24} lg={8}>
                <PreventDownloadField
                  tooltip="By checking this box users will be unable to duplicate the selected asset(s)."
                  checkboxProps={{
                    indeterminate: preventDownloadIndeterminate,
                    onChange: () => setPreventDownloadIndeterminate(false),
                  }}
                />
              </Col>
            )}
            {fieldsToShow.includes('preventDuplicate') && (
              <Col md={24} lg={8}>
                <PreventDuplicateField
                  tooltip="By checking this box users will be unable to download the selected asset(s)."
                  checkboxProps={{
                    indeterminate: preventDuplicateIndeterminate,
                    onChange: () => setPreventDuplicateIndeterminate(false),
                  }}
                />
              </Col>
            )}
          </Row>
          <FormActionButtons
            submitButtonLabel={`Apply to ${learningContents.length} ${pluralize('Item', learningContents.length)}`}
            onCancel={onCancel}
          />
        </Form>
      </Formik>
    </ContainerDiv>
  )
}
