import React, { useCallback, useEffect, useState } from 'react'
import { ContainerDiv, FormHeaderText, FjCard } from 'src/components/Common'
import { DateFilter } from 'src/components/Common/DateFilter'
import { GroupSelect } from 'src/components/Common/GroupSelect'
import { SectionTitle, ViewsSummaryByUserType } from 'src/components/Feed/AssetSummary'
import { ViewsSummaryTable } from 'src/components/Feed/ViewsSummaryTable'
import { FeedPost } from 'src/models/FeedPost'
import { User } from 'src/models/User'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { clearFalseyValues } from 'src/utils/format'

export type PostAnalyticsModalProps = {
  learningContent: { id: string; title: string }
  initialGroupId?: string
  initialStartDate?: moment.Moment
  initialEndDate?: moment.Moment
}

export const PostAnalyticsModal = ({
  learningContent,
  initialGroupId,
  initialStartDate,
  initialEndDate,
}: PostAnalyticsModalProps) => {
  const [groupId, setGroupId] = useState<string | undefined>(
    initialGroupId || sharedDataStore.user.getFirstNonDefaultGroupIfExists().id
  )
  const [startDate, setStartDate] = useState<moment.Moment>(initialStartDate)
  const [endDate, setEndDate] = useState<moment.Moment>(initialEndDate)
  const [viewsSummaryByUser, setViewsSummaryByUser] = useState<ViewsSummaryByUserType[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleDateFilterChange = (dateRange: moment.Moment[]) => {
    const startDate = dateRange?.[0] ? dateRange[0] : undefined
    const endDate = dateRange?.[1] ? dateRange[1] : undefined

    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleClearDateFilter = () => {
    setStartDate(undefined)
    setEndDate(undefined)
  }

  const updateViewsSummaryByUser = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await FeedPost.getViewsSummaryByUser(
        learningContent.id,
        clearFalseyValues({
          group_id: groupId,
          start_date: startDate?.toISOString(),
          end_date: endDate?.toISOString(),
        })
      )
      setViewsSummaryByUser(data.map((item: ViewsSummaryByUserType) => ({ ...item, user: User.fromData(item.user) })))
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, true)
    } finally {
      setIsLoading(false)
    }
  }, [endDate, groupId, learningContent.id, startDate])

  useEffect(() => {
    updateViewsSummaryByUser()
  }, [updateViewsSummaryByUser])

  return (
    <ContainerDiv>
      <FormHeaderText heading={`Analytics for "${learningContent.title}"`} />
      <ContainerDiv display="flex" justifyContent="end" gap="15px" alignItems="center" marginBottom="15px">
        {sharedDataStore.user.isPartOfManyGroups() ? (
          <GroupSelect value={groupId} onChange={(groupId: string) => setGroupId(groupId)} />
        ) : null}
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleDateFilterChange={handleDateFilterChange}
          handleClearDateFilter={handleClearDateFilter}
        />
      </ContainerDiv>
      <FjCard marginBottom marginTop="20px" padding="12px">
        <SectionTitle>User Views</SectionTitle>
        <ViewsSummaryTable loading={isLoading} viewsSummaryByUser={viewsSummaryByUser} />
      </FjCard>
    </ContainerDiv>
  )
}
