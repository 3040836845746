import { Tooltip } from 'antd'
import React from 'react'
import { AlertCircle, Columns, FastForward, Grid } from 'react-feather'
import { ContainerDiv, FjText } from 'src/components/Common'
import { ContentTag } from 'src/components/Feed/ContentTag'
import { FeedContentImageCardProps } from 'src/components/Library/FeedContentImageCard'
import { Colors } from 'src/constants/colors'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { FeedPost } from 'src/models/FeedPost'
import { Prompt } from 'src/models/Prompt'
import { QueueItem } from 'src/models/QueueItem'
import { SfOpportunity } from 'src/models/SfOpportunity'
import SalesforceBlueIcon from 'src/assets/icons/SalesforceBlue.svg'
import { formatDuration } from 'src/utils/format'
import { Call } from 'src/models/Call'
import { Asset } from 'src/models/Asset'
import { DealRoom } from 'src/models/DealRoom'
import { ViewMode } from 'src/store/DataStore'

const OverlayIconWrapper: React.FC<
  React.PropsWithChildren & { backgroundColor?: string; flexDirection?: 'column' | 'row' }
> = ({ children, backgroundColor = 'white', flexDirection = 'row' }) => {
  return (
    <ContainerDiv
      backgroundColor={backgroundColor}
      display="flex"
      flexDirection={flexDirection}
      alignItems="center"
      justifyContent="center"
      padding="4px"
      borderRadius={6}
      marginBottom="2px"
    >
      {children}
    </ContainerDiv>
  )
}

export const DurationInfo = ({ targetObj }: { targetObj: FeedPost | Call }) => {
  let duration

  if (targetObj instanceof FeedPost) {
    duration = targetObj.videoMetadata?.duration
  } else {
    duration = targetObj.duration
  }

  if (!duration) return null

  return (
    <FjText
      fontSize="small"
      fontWeight="semi-bold"
      padding="0px 2px"
      color={Colors.white}
      backgroundColor={Colors.squirrel}
      position="absolute"
      bottom="9px"
      right="5px"
    >
      {formatDuration(duration)}
    </FjText>
  )
}

export const QueueItemDueDateInfo = ({ obj }: { obj: QueueItem }) => {
  if (!obj.dueDate) return null

  return (
    <FjText
      zIndex={1000}
      backgroundColor={Colors.brownGray}
      padding="2px 3px"
      fontSize="10px"
      display="flex"
      position="absolute"
      top={2}
      right={5}
      color={obj.isOverdue() ? Colors.cinnabar : ''}
      alignItems="center"
    >
      {obj.isOverdue() && <AlertCircle size={12} color={Colors.clementine} />}
      Due: {obj.getDueDateDisplay()}
    </FjText>
  )
}

const ContentCountOverlay: React.FC<{ count: number }> = ({ count }) => {
  if (count === 0) return null

  return (
    <ContainerDiv position="absolute" top={8} right={8}>
      <OverlayIconWrapper flexDirection="column">
        <FjText fontSize="small" fontWeight="semi-bold" display="block">
          {count}
        </FjText>
        <Grid size={14} />
      </OverlayIconWrapper>
    </ContainerDiv>
  )
}

export const LibraryCardOverlay = ({
  obj,
  compressView,
  viewMode = 'card',
}: {
  obj: FeedContentImageCardProps['obj']
  compressView?: boolean
  viewMode?: ViewMode
}) => {
  const isQueueItem = obj instanceof QueueItem
  const targetObj = isQueueItem ? obj.linkedObject : obj
  const isFeedPost = targetObj instanceof FeedPost
  const isCall = targetObj instanceof Call
  const isPlaylist = targetObj instanceof FeedPlaylist
  const isPrompt = targetObj instanceof Prompt
  const isOpportunity = targetObj instanceof SfOpportunity
  const isAsset = targetObj instanceof Asset
  const isDealRoom = targetObj instanceof DealRoom
  const hasOpportunity = targetObj.hasOpportunity()
  const hasStageName = targetObj.hasStageName()
  const isTemplate = (isAsset || isDealRoom) && !!targetObj.templateId

  return (
    <>
      {!compressView ? (
        <ContainerDiv position="absolute" top={8} left={8}>
          <ContentTag obj={targetObj} includeFileType />
        </ContainerDiv>
      ) : null}
      {isFeedPost || isCall ? <DurationInfo targetObj={targetObj} /> : null}
      {isOpportunity ? (
        <FjText textAlign="left" fontSize="small" position="absolute" bottom="9px" right="5px">
          {targetObj.stageName}
        </FjText>
      ) : null}
      {isQueueItem ? <QueueItemDueDateInfo obj={obj} /> : null}
      <ContainerDiv position="absolute" zIndex={2} top={8} right={8} justifyContent="center" alignItems="center">
        {viewMode === 'card' && (hasOpportunity || hasStageName) && (
          <OverlayIconWrapper backgroundColor={targetObj.getTagBgColor()}>
            <Tooltip
              title={hasOpportunity ? targetObj.sfMetadata.name : hasStageName ? targetObj.sfMetadata.stageName : null}
            >
              {hasOpportunity ? (
                <img src={SalesforceBlueIcon} alt="sales-force-icon" height="10px" />
              ) : hasStageName ? (
                <FastForward size={14} color={Colors.cornflowerBlue} />
              ) : null}
            </Tooltip>
          </OverlayIconWrapper>
        )}
        {isTemplate && (
          <OverlayIconWrapper backgroundColor={targetObj.getTagBgColor()}>
            <Tooltip title="Template">
              <Columns size={14} />
            </Tooltip>
          </OverlayIconWrapper>
        )}
      </ContainerDiv>
      {(isPlaylist || isOpportunity || isPrompt) && <ContentCountOverlay count={targetObj.contentCount} />}
    </>
  )
}
