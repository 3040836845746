import React, { Suspense } from 'react'
import {
  PageLayout,
  PageContent,
  PageHeading,
  FloatingPanel,
  DefaultButton,
  FjText,
  FjCard,
  ContainerDiv,
} from 'src/components/Common'
import { FJNavbar } from 'src/components/Common/FJNavbar'
import { FeedSideNavbar, MobileFeedSideNavbar, PublicFeedSidebar } from 'src/components/Common/FeedSideNavbar'
import { ContentWrapper } from 'src/components/Page/ContentWrapper'
import { Colors } from 'src/constants/colors'
import { mobileQuery } from 'src/pages/public/common'
import { Drawer } from 'antd'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { FORM_CONTAINER_MODAL_ACTIONS } from 'src/components/Common/FormContainerModal'
import { FormContainerModal } from 'src/components/Common/FormContainerModal'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx'
import { VideoRecorder } from 'src/components/Feed/VideoRecorder'
import { FjModal } from 'src/components/Common/Modals'
import { FeedNavbar, PublicFeedNavbar, IPublicFeedNavbarProps } from 'src/components/Feed/FeedNavbar'
import { Notifications } from 'src/components/Feed/Notifications'
import { DeleteDialog } from 'src/components/Feed/DeleteDialog'
import { QueueDrawer } from 'src/components/Feed/QueueDrawer'
import { MetaData } from 'src/hoc/MetaData'
import { sharedScrollStore } from 'src/store/ScrollStore'
import { SharedConfirmDialog } from 'src/components/Common/SharedConfirmDialog'
import { sharedDataStore } from 'src/store/DataStore'
import { LearningPathNavigation } from 'src/components/LearningPath/LearningPathNavigation'
import { LearningPathContentModal } from 'src/components/LearningPath/LearningPathContentModal'
import { useUpdateCheck } from 'react-update-notification'
import lightBlueSparkles from 'src/assets/icons/lighBlueSparkles.svg'
import { BulkEditPopup } from 'src/components/Library/BulkEditPopup'
import { AISummaryFeedbackModal } from 'src/components/Search/AIGeneratedSummary'

const UpdateAvailableContainer: React.FC = () => {
  const { status, reloadPage } = useUpdateCheck({ type: 'interval', interval: 60000 })

  if (status === 'checking' || status === 'current') return null

  return (
    <FjCard
      display="flex"
      padding="16px"
      justifyContent="center"
      alignItems="center"
      gap={5}
      boxShadow="2px 2px 2px rgba(0, 0, 0, 0.05)"
      position="fixed"
      bottom={20}
      right={10}
      zIndex={10000}
    >
      <img src={lightBlueSparkles} alt="New version" style={{ width: 40 }} />
      <ContainerDiv display="flex" flexDirection="column" gap={5}>
        <FjText fontWeight="semi-bold">New version available!</FjText>
        <DefaultButton title="Click to Update" clicked={reloadPage} buttonType="primary" size="small" />
      </ContainerDiv>
    </FjCard>
  )
}

interface IPageProps extends React.PropsWithChildren {
  container?: boolean
  heading?: string | React.ReactElement
  hideNavbar?: boolean
  hideSidebar?: boolean
  navbar?: JSX.Element
  breadcrumbs?: Array<{ title: string; url: string }>
  containerBackgroundColor?: string
  containerClassName?: string
  contentBackgroundColor?: string
  contentMaxWidth?: string
  containerStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
  title?: string
}

const BasePage: React.FC<IPageProps> = ({
  container = true,
  navbar = null,
  heading,
  breadcrumbs,
  containerBackgroundColor,
  contentBackgroundColor,
  contentMaxWidth,
  containerStyle,
  children,
  contentStyle,
  containerClassName,
}) => {
  return (
    <React.Fragment>
      {heading && <PageHeading heading={heading} breadcrumbs={breadcrumbs} />}
      <PageContent backgroundColor={contentBackgroundColor} style={contentStyle}>
        {/* No idea why but PageContent only wants 1 child ¯\_(ツ)_/¯ */}
        <div>
          {navbar}
          {container ? (
            <ContentWrapper
              backgroundColor={containerBackgroundColor}
              contentMaxWidth={contentMaxWidth}
              style={containerStyle}
              className={containerClassName}
            >
              {children}
            </ContentWrapper>
          ) : (
            children
          )}
          <UpdateAvailableContainer />
        </div>
      </PageContent>
    </React.Fragment>
  )
}

export const Page: React.FC<IPageProps> = ({
  container = true,
  heading,
  breadcrumbs,
  containerBackgroundColor,
  contentBackgroundColor,
  containerStyle,
  children,
  contentStyle,
  title,
}) => {
  return (
    <MetaData title={title}>
      <PageLayout>
        <FJNavbar />
        <BasePage
          container={container}
          heading={heading}
          breadcrumbs={breadcrumbs}
          containerBackgroundColor={containerBackgroundColor}
          contentBackgroundColor={contentBackgroundColor}
          containerStyle={containerStyle}
          children={children}
          contentStyle={contentStyle}
        />
      </PageLayout>
    </MetaData>
  )
}

@observer
class FeedPageWrapper extends React.Component<React.PropsWithChildren> {
  constructor(props) {
    super(props)
    sharedAppStateStore.isMobile = window.matchMedia(mobileQuery.query).matches
  }

  componentDidMount() {
    sharedScrollStore.scrollToTop()
    window.matchMedia(mobileQuery.query).addEventListener('change', sharedAppStateStore.mediaQueryChangerListener)
  }

  componentWillUnmount() {
    window.matchMedia(mobileQuery.query).removeEventListener('change', sharedAppStateStore.mediaQueryChangerListener)
  }

  render() {
    return this.props.children
  }
}

@observer
export class FeedPage extends React.Component<IPageProps> {
  @observable navDrawerOpen = false

  setNavDrawerOpen = (open: boolean) => (this.navDrawerOpen = open)

  handleCloseFeedPostModal = () => {
    if (sharedAppStateStore.feedPostModalProps.isFeedPostUpdated) {
      sharedAppStateStore.sharedConfirmDialogProps = {
        onConfirm: sharedAppStateStore.feedPostModalProps.onCancel,
        title: 'Discard Post',
        content: 'Are you sure you want to discard this post? You cannot undo this action.',
        confirmButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
      }
    } else {
      sharedAppStateStore.feedPostModalProps.onCancel()
    }
  }

  handleClosePromptModal = () => {
    if (sharedAppStateStore.promptModalProps.isPromptUpdated) {
      sharedAppStateStore.sharedConfirmDialogProps = {
        onConfirm: sharedAppStateStore.promptModalProps.onCancel,
        title: 'Discard Prompt',
        content: 'Are you sure you want to discard this prompt? You cannot undo this action.',
        confirmButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
      }
    } else {
      sharedAppStateStore.promptModalProps.onCancel()
    }
  }

  handleCloseAssetModal = () => {
    if (sharedAppStateStore.assetModalProps.isFileUploaded || sharedAppStateStore.assetModalProps.isAssetUpdated) {
      sharedAppStateStore.sharedConfirmDialogProps = {
        onConfirm: sharedAppStateStore.assetModalProps.onCancel,
        title: 'Discard Asset',
        content: 'Are you sure you want to discard this asset? You cannot undo this action.',
        confirmButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
      }
    } else {
      sharedAppStateStore.assetModalProps.onCancel()
    }
  }

  constructor(props: IPageProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    const {
      container = true,
      heading,
      breadcrumbs,
      containerBackgroundColor = Colors.athensGray,
      contentBackgroundColor = Colors.athensGray,
      contentMaxWidth,
      containerStyle,
      children,
      contentStyle,
      title,
      containerClassName,
      hideNavbar,
    } = this.props

    const showSidebar = !sharedDataStore.user.isAnonymous() && !sharedAppStateStore.isMobile

    let navbar: any

    if (hideNavbar) navbar = null
    else if (sharedAppStateStore.externalAcademy?.getCustomHeaderIfExists())
      navbar = <Suspense fallback={<></>}>{sharedAppStateStore.externalAcademy?.getCustomHeaderIfExists()}</Suspense>
    else navbar = <FeedNavbar />

    return (
      <MetaData title={title}>
        <PageLayout>
          <FeedPageWrapper>
            <LearningPathNavigation />
            {sharedAppStateStore.learningPathContentModalProps ? (
              <LearningPathContentModal {...sharedAppStateStore.learningPathContentModalProps} />
            ) : null}
            <Notifications />
            <QueueDrawer />
            {showSidebar && <FeedSideNavbar />}
            {sharedAppStateStore.isMobile && (
              <>
                <Drawer placement="left" open={this.navDrawerOpen} headerStyle={{ display: 'none' }} width="100%">
                  <MobileFeedSideNavbar setNavbarDrawerOpen={this.setNavDrawerOpen} />
                </Drawer>
                <FloatingPanel openMobileDrawer={this.setNavDrawerOpen} />
              </>
            )}
            <FjModal
              maskClosable
              open={!!sharedAppStateStore.aiSummaryFeedbackModalProps}
              onCancel={() => sharedAppStateStore?.aiSummaryFeedbackModalProps?.onCancel()}
              footer={null}
              destroyOnClose
            >
              <AISummaryFeedbackModal {...sharedAppStateStore.aiSummaryFeedbackModalProps} />
            </FjModal>
            {!!sharedAppStateStore.feedPostModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.feedPostModalProps}
                handleClose={this.handleCloseFeedPostModal}
                action={FORM_CONTAINER_MODAL_ACTIONS.FeedPost}
                maskClosable={!sharedAppStateStore.videoRecorderProps}
                formComponentProps={{ ...sharedAppStateStore.feedPostModalProps }}
                hide={sharedAppStateStore.hideVideoRecordModal || !!sharedAppStateStore.videoModalProps}
              />
            )}
            {!!sharedAppStateStore.promptModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.promptModalProps}
                handleClose={this.handleClosePromptModal}
                action={FORM_CONTAINER_MODAL_ACTIONS.Prompt}
                formComponentProps={{ ...sharedAppStateStore.promptModalProps }}
              />
            )}
            {!!sharedAppStateStore.addContentToContainerModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.addContentToContainerModalProps}
                handleClose={sharedAppStateStore.addContentToContainerModalProps.onCancel}
                action={FORM_CONTAINER_MODAL_ACTIONS.AddContentToContentContainer}
                formComponentProps={{ ...sharedAppStateStore.addContentToContainerModalProps }}
              />
            )}
            {!!sharedAppStateStore.addToQueueModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.addToQueueModalProps}
                handleClose={sharedAppStateStore.addToQueueModalProps.onCancel}
                action={FORM_CONTAINER_MODAL_ACTIONS.AddToQueue}
                formComponentProps={{ ...sharedAppStateStore.addToQueueModalProps }}
              />
            )}
            {!!sharedAppStateStore.modalProps && (
              <FjModal
                footer={null}
                bodyStyle={{ padding: '0px 10px' }}
                onCancel={() => (sharedAppStateStore.modalProps = undefined)}
                open
                {...sharedAppStateStore.modalProps}
              />
            )}
            {sharedAppStateStore.videoRecorderProps && <VideoRecorder {...sharedAppStateStore.videoRecorderProps} />}
            {!!sharedAppStateStore.videoModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.videoModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.VideoModalForm}
                hide={sharedAppStateStore.hideVideoRecordModal}
                maskClosable={!sharedAppStateStore.videoRecorderProps}
                handleClose={() => (sharedAppStateStore.videoModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.videoModalProps }}
              />
            )}
            {!!sharedAppStateStore.assetModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.assetModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.AssetForm}
                handleClose={this.handleCloseAssetModal}
                formComponentProps={{ ...sharedAppStateStore.assetModalProps }}
              />
            )}
            {!!sharedAppStateStore.assetSummaryModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.assetSummaryModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.AssetSummary}
                handleClose={() => (sharedAppStateStore.assetSummaryModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.assetSummaryModalProps }}
              />
            )}
            {!!sharedAppStateStore.postAnalyticsModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.postAnalyticsModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.PostAnalyticsModal}
                handleClose={() => (sharedAppStateStore.postAnalyticsModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.postAnalyticsModalProps }}
              />
            )}
            {!!sharedAppStateStore.contentProgressDetailsModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.contentProgressDetailsModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.ContentProgressDetails}
                handleClose={() => (sharedAppStateStore.contentProgressDetailsModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.contentProgressDetailsModalProps }}
              />
            )}
            {!!sharedAppStateStore.confettiModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.confettiModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.ConfettiModal}
                handleClose={() => (sharedAppStateStore.confettiModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.confettiModalProps }}
              />
            )}
            {!!sharedAppStateStore.rubricFormModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.rubricFormModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.RubricForm}
                handleClose={() => (sharedAppStateStore.rubricFormModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.rubricFormModalProps }}
              />
            )}
            {!!sharedAppStateStore.rubricAuthoringFormModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.rubricAuthoringFormModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.RubricAuthoringForm}
                handleClose={() => (sharedAppStateStore.rubricAuthoringFormModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.rubricAuthoringFormModalProps }}
              />
            )}
            {!!sharedAppStateStore.sharedContentModalProps && (
              <FormContainerModal
                hide={!!sharedAppStateStore.sharedContentSessionModalProps}
                open={!!sharedAppStateStore.sharedContentModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.SharedContentModal}
                handleClose={sharedAppStateStore.sharedContentModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.sharedContentModalProps }}
              />
            )}
            {!!sharedAppStateStore.sharedContentSessionModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.sharedContentSessionModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.SharedContentSessions}
                handleClose={sharedAppStateStore.sharedContentSessionModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.sharedContentSessionModalProps }}
              />
            )}
            {!!sharedAppStateStore.shareCourseFormModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.shareCourseFormModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.ShareCourseForm}
                handleClose={sharedAppStateStore.shareCourseFormModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.shareCourseFormModalProps }}
              />
            )}
            {!!sharedAppStateStore.sharedLinkModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.sharedLinkModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.SharedLinkModal}
                handleClose={() => (sharedAppStateStore.sharedLinkModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.sharedLinkModalProps }}
              />
            )}
            {!!sharedAppStateStore.queueModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.queueModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.QueueModal}
                handleClose={() => (sharedAppStateStore.queueModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.queueModalProps }}
              />
            )}
            {!!sharedAppStateStore.teamProgressModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.teamProgressModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.TeamProgressModal}
                handleClose={() => (sharedAppStateStore.teamProgressModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.teamProgressModalProps }}
              />
            )}
            {!!sharedAppStateStore.addContentModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.addContentModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.AddContentModal}
                handleClose={() => (sharedAppStateStore.addContentModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.addContentModalProps }}
              />
            )}
            {!!sharedAppStateStore.dealRoomAnalyticsModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.dealRoomAnalyticsModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.DealRoomAnalyticsModal}
                handleClose={() => (sharedAppStateStore.dealRoomAnalyticsModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.dealRoomAnalyticsModalProps }}
              />
            )}
            {!!sharedAppStateStore.editDriveFileModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.editDriveFileModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.EditDriveFile}
                handleClose={sharedAppStateStore.editDriveFileModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.editDriveFileModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.copyClipboardModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.copyClipboardModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.CopyClipboardModal}
                handleClose={sharedAppStateStore.copyClipboardModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.copyClipboardModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.templateAuthoringModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.templateAuthoringModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.TemplateAuthoringModal}
                handleClose={sharedAppStateStore.templateAuthoringModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.templateAuthoringModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.templateModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.templateModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.TemplateModal}
                handleClose={sharedAppStateStore.templateModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.templateModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.liveSessionAttendanceModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.liveSessionAttendanceModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.LiveSessionAttendanceModal}
                handleClose={() => (sharedAppStateStore.liveSessionAttendanceModalProps = undefined)}
                formComponentProps={{ ...sharedAppStateStore.liveSessionAttendanceModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.editSharedContentModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.editSharedContentModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.EditSharedContentModal}
                handleClose={sharedAppStateStore.editSharedContentModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.editSharedContentModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.bulkEditFormModalProps && (
              <FormContainerModal
                open={!!sharedAppStateStore.bulkEditFormModalProps}
                action={FORM_CONTAINER_MODAL_ACTIONS.BulkEditForm}
                handleClose={sharedAppStateStore.bulkEditFormModalProps.onCancel}
                formComponentProps={{ ...sharedAppStateStore.bulkEditFormModalProps }}
                maskClosable
              />
            )}
            {!!sharedAppStateStore.deleteDialogProps && <DeleteDialog />}
            {!!sharedAppStateStore.sharedConfirmDialogProps && <SharedConfirmDialog />}
            {!!sharedAppStateStore.bulkEditPopupProps && <BulkEditPopup {...sharedAppStateStore.bulkEditPopupProps} />}
            <BasePage
              container={container}
              heading={heading}
              breadcrumbs={breadcrumbs}
              containerBackgroundColor={containerBackgroundColor}
              contentBackgroundColor={contentBackgroundColor}
              contentMaxWidth={contentMaxWidth}
              containerStyle={containerStyle}
              contentStyle={{ ...contentStyle, padding: (contentStyle && contentStyle.padding) || '0px' }}
              navbar={navbar}
              children={children}
              containerClassName={containerClassName}
            />
          </FeedPageWrapper>
        </PageLayout>
        {sharedAppStateStore.externalAcademy?.getCustomFooterIfExists() ? (
          <Suspense fallback={<></>}>
            <div className="custom-footer">{sharedAppStateStore.externalAcademy?.getCustomFooterIfExists()}</div>
          </Suspense>
        ) : null}
      </MetaData>
    )
  }
}

interface IPublicFeedPageProps extends IPageProps {
  navbarProps?: IPublicFeedNavbarProps
}

@observer
export class PublicFeedPage extends React.Component<IPublicFeedPageProps> {
  render() {
    const {
      container = true,
      heading,
      breadcrumbs,
      containerBackgroundColor = Colors.athensGray,
      contentBackgroundColor = Colors.athensGray,
      contentMaxWidth,
      containerStyle,
      children,
      contentStyle,
      title,
      containerClassName,
      hideNavbar,
      hideSidebar = false,
      navbarProps = {},
    } = this.props

    const showSidebar =
      !sharedDataStore.user.isAnonymous() &&
      !sharedDataStore.user.isFaasPublic() &&
      !sharedAppStateStore.isMobile &&
      !hideSidebar

    let navbar: any
    if (hideNavbar) navbar = null
    else if (sharedAppStateStore.externalAcademy?.getCustomHeaderIfExists())
      navbar = <Suspense fallback={<></>}>{sharedAppStateStore.externalAcademy?.getCustomHeaderIfExists()}</Suspense>
    else navbar = <PublicFeedNavbar {...navbarProps} />

    return (
      <MetaData title={title}>
        <PageLayout>
          <FeedPageWrapper>
            {showSidebar && <PublicFeedSidebar />}
            <BasePage
              container={container}
              heading={heading}
              breadcrumbs={breadcrumbs}
              containerBackgroundColor={containerBackgroundColor}
              contentBackgroundColor={contentBackgroundColor}
              contentMaxWidth={contentMaxWidth}
              containerStyle={containerStyle}
              contentStyle={{ ...contentStyle, padding: contentStyle && contentStyle.padding }}
              navbar={navbar}
              children={children}
              containerClassName={containerClassName}
            />
          </FeedPageWrapper>
        </PageLayout>
        {sharedAppStateStore.externalAcademy?.getCustomFooterIfExists() ? (
          <Suspense fallback={<></>}>
            <div className="custom-footer">{sharedAppStateStore.externalAcademy?.getCustomFooterIfExists()}</div>
          </Suspense>
        ) : null}
      </MetaData>
    )
  }
}
